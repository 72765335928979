import { Button } from '@mui/material';
import styled from 'styled-components';
import { screenLgMin } from '../../../../shared/styles';

const StyledButton = styled(Button)`
  margin: 0 var(--spacing-sm);
  color: var(--color-white);
  background-color: var(--color-black);
  border-radius: 30px;
  border: 1px solid var(--color-black);
  width: 150px;
  height: 40px;
  font-size: 12px;
  text-transform: capitalize;
  cursor: pointer;
  transition:
    background-color 0.3s,
    color 0.3s;

  @media (min-width: ${screenLgMin}) {
    width: 150px;
    height: 40px;
    font-size: 14px;
  }

  &:hover {
    background-color: var(--color-white);
    color: var(--color-black);
  }
`;

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface CustomStyledButtonProps {
  text: string;
  onClick?: () => void;
}

export const CustomStyledButton: React.FC<CustomStyledButtonProps> = ({
  text,
  onClick,
}) => {
  return (
    <CenteredDiv>
      <StyledButton onClick={onClick}>{text}</StyledButton>
    </CenteredDiv>
  );
};
