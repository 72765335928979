import React from 'react';
import styled from 'styled-components';

type PillProps = {
  isActive: boolean;
  handleClick: () => void;
  label: string;
};

const StyledPill = styled.div<{ active: boolean }>`
  display: inline-block;
  padding: 12px 18px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 8px;
  cursor: pointer;
  min-width: 150px;
  text-align: center;
  transition: all 0.3s ease;
  background-color: ${(props) => (props.active ? '#3498db' : '#ccc')};
  color: ${(props) => (props.active ? '#fff' : '#333')};
  transform: ${(props) => (props.active ? 'translateY(2px)' : 'translateY(0)')};
  &:hover {
    background-color: ${(props) => (props.active ? '#2980b9' : '#bbb')};
  }
`;

const Pill = ({ label, isActive, handleClick }: PillProps) => {
  return (
    <StyledPill active={isActive} onClick={handleClick}>
      {label}
    </StyledPill>
  );
};

type PillContainerProps = {
  pills: { id: number; label: string; isActive: boolean }[];
  handleFilterClick: (id: number) => void;
};

const StyledPillWrapper = styled.div`
  display: flex;
  margin: var(--spacing-sm);
`;

const Pills = ({ pills, handleFilterClick }: PillContainerProps) => {
  return (
    <StyledPillWrapper>
      {pills.map((pill) => (
        <Pill
          key={pill.id}
          label={pill.label}
          isActive={pill.isActive}
          handleClick={() => handleFilterClick(pill.id)}
        />
      ))}
    </StyledPillWrapper>
  );
};

export default Pills;
