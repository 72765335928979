import { COMPANY_SIZE_OPTIONS } from '../../company-search/types/company.types';

export enum AdminActionsEnum {
  DELETE_USER = 'DELETE_USER',
  DELETE_WAITLIST_USER = 'DELETE_WAITLIST_USER',
  SEND_INVITE = 'SEND_INVITE',
  PROMOTE_TO_ADMIN = 'PROMOTE_TO_ADMIN',
  APPROVE_COMPANY = 'APPROVE_COMPANY',
  MIGRATE_COMPANY_REVIEWS = 'MIGRATE_COMPANY_REVIEWS',
}

export type WaitlistEntry = {
  firstName: string;
  lastName: string;
  companiesWorkedWith: Array<string>;
  email: string;
  createdAt: string;
  actions: Array<{ action: AdminActionsEnum; typeVariant: string }>;
};

export const COMPANY_FIELDS_MAPPINGS = [
  {
    name: 'companyName',
    label: 'Company Name',
    required: true,
    minLength: 3,
    maxLength: 50,
    componentType: 'TextField',
    type: 'text',
  },

  {
    name: 'yearFounded',
    label: 'Year Founded',
    required: true,
    componentType: 'TextField',
    type: 'string',
  },
  {
    name: 'marketCap',
    label: 'Market Capitalisation',
    required: true,
    componentType: 'TextField',
    type: 'text',
  },
  {
    name: 'url',
    label: 'Website URL',
    required: true,
    componentType: 'TextField',
    type: 'url',
  },
  {
    name: 'stockTicker',
    label: 'Stock Ticker',
    required: true,
    minLength: 1,
    maxLength: 10,
    componentType: 'TextField',
    type: 'text',
  },
  {
    name: 'country',
    label: 'Country',
    required: true,
    minLength: 2,
    maxLength: 50,
    componentType: 'TextField',
    type: 'text',
  },
  {
    name: 'state',
    label: 'State',
    required: false,
    minLength: 2,
    maxLength: 50,
    componentType: 'AutoComplete',
    type: 'text',
  },
  {
    name: 'city',
    label: 'City',
    required: false,
    minLength: 2,
    maxLength: 50,
    componentType: 'TextField',
    type: 'text',
  },
  {
    name: 'companySize',
    label: 'Company Size',
    required: true,
    componentType: 'Select',
    options: COMPANY_SIZE_OPTIONS,
  },
  {
    name: 'revenue',
    label: 'Revenue ($M)',
    required: true,
    componentType: 'TextField',
  },
  {
    name: 'profile',
    label: 'Profile',
    required: true,
    minLength: 3,
    maxLength: 500,
    componentType: 'TextArea',
    type: 'text',
  },
];

export const usaStates = [
  { name: 'Alabama', abbreviation: 'AL' },
  { name: 'Alaska', abbreviation: 'AK' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  { name: 'Hawaii', abbreviation: 'HI' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Rhode Island', abbreviation: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' },
];

export type AdminAction = {
  action: string;
  typeVariant: string;
};

export type ReviewData = {
  id: string;
  companyName: string;
  reviewId: number;
  companyId: number;
  createdAt: Date;
  actions: Array<AdminAction>;
};
