import React, {
  createContext,
  SetStateAction,
  useState,
  Dispatch,
  useEffect,
} from 'react';
import { Amplify, Auth } from 'aws-amplify';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import {
  User,
  UserPoolData,
} from '../../scenes/authentication/types/types.auth';

export enum USERTYPENUM {
  USER = 'User',
  COMPANY = 'Company',
}

export const amplifyConfig = Amplify.configure({
  aws_cognito_region: 'us-west-1',
  aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
  aws_mandatory_sign_in: 'enable',
  oauth: {
    domain: 'https://kandir.auth.us-west-1.amazoncognito.com',
    scope: ['email', 'openid', 'profile'],
    redirectSignIn: 'https://www.kandir.io',
    redirectSignOut: 'https://www.kandir.io',
    responseType: 'code',
  },
});

export enum UserAuthenticationEnum {
  NOT_AUTH = 'User not authenticated',
}

export interface IUserContext {
  currentUser: User | null | undefined;
  setCurrentUser: Dispatch<SetStateAction<User | null | undefined>>;
  authenticate: (username: string, password: string) => Promise<void>;
  setUserDetails: () => void;
  setReload: (value: number) => void;
  reload: number;
}

export const UserContext = createContext<IUserContext>({
  currentUser: null,
  authenticate: async () => {},
  setCurrentUser: () => {},
  setUserDetails: () => {},
  setReload: () => {},
  reload: 0,
});

const newUserPool = new CognitoUserPool(UserPoolData);

export const UserProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [currentUser, setCurrentUser] = useState<User | null>();
  const [reload, setReload] = useState(0);

  const fetchUserDetails = async (email: string, token: string) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/user/${email}`,
      { headers: { Authorization: token } }
    );
    return response.data;
  };

  const setUserDetails = async () => {
    try {
      const loggedInUser = await Auth.currentAuthenticatedUser();
      const idToken = loggedInUser.signInUserSession.idToken?.jwtToken;
      const tokenData = jwt_decode(idToken) as any;
      const userDetails: User = await fetchUserDetails(
        tokenData.email,
        idToken
      );

      setCurrentUser({
        email: tokenData.email,
        idToken,
        firstName: userDetails?.firstName,
        lastName: userDetails?.lastName,
        company: userDetails?.company,
        industry: userDetails.industry,
        positionTitle: userDetails.positionTitle,
        Companies: userDetails.Companies,
        id: userDetails?.id,
        hasCompletedRegistration: !!userDetails,
        createdAt: userDetails?.createdAt,
        reviews: userDetails?.reviews,
        companySize: userDetails?.companySize,
        onboardingConfig: userDetails?.onboardingConfig,
      });
    } catch (error) {
      setCurrentUser(null);
    }
  };

  const authenticate = async (
    Username: string,
    Password: string
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      const authDetails = new AuthenticationDetails({ Username, Password });
      const user = new CognitoUser({ Username, Pool: newUserPool });

      user.authenticateUser(authDetails, {
        onSuccess: async (cognitoSession: CognitoUserSession) => {
          const idToken = cognitoSession.getIdToken().getJwtToken();
          const tokenData = jwt_decode(idToken) as any;
          const userDetails: any = await fetchUserDetails(
            tokenData.email,
            idToken
          );

          setCurrentUser({
            email: tokenData.email,
            idToken,
            firstName: userDetails?.firstName,
            lastName: userDetails?.lastName,
            company: userDetails?.company,
            industry: userDetails.industry,
            positionTitle: userDetails.positionTitle,
            Companies: userDetails.Companies,
            id: userDetails?.id,
            hasCompletedRegistration: !!userDetails,
            createdAt: userDetails?.createdAt,
            reviews: userDetails?.reviews,
            companySize: userDetails?.companySize,
            onboardingConfig: userDetails?.onboardingConfig,
          });

          resolve();
        },
        onFailure: reject,
        newPasswordRequired: resolve,
      });
    });
  };

  useEffect(() => {
    setUserDetails();
  }, [reload]);

  const value: IUserContext = {
    currentUser,
    setCurrentUser,
    authenticate,
    setUserDetails,
    reload,
    setReload,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
