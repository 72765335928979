import { Button } from '@mui/material';
import styled from 'styled-components';
import { H1, screenMdMin } from '../../../shared/styles';

type CompanyProfileHeaderProps = {
  name: string;
  openReviewDialog: (isOpen: boolean) => void;
  followCompany: () => void;
  isCompanyFollowed: boolean;
};

const StyledHeader = styled(H1)`
  padding: var(--spacing-md);
  margin: auto;
  text-align: center;
  text-decoration: underline;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  height: 50px;
  @media ${screenMdMin} {
    height: 30px;
  }
`;

const CompanyProfileHeader = (props: CompanyProfileHeaderProps) => {
  const { name, openReviewDialog, followCompany, isCompanyFollowed } = props;

  return (
    <>
      <HeaderWrapper>
        <StyledButton variant="contained" onClick={followCompany}>
          {isCompanyFollowed ? 'Unfollow' : 'Follow'} Company
        </StyledButton>
        <StyledHeader>{name}</StyledHeader>
        <StyledButton
          variant="contained"
          onClick={() => openReviewDialog(true)}
        >
          Leave a Review
        </StyledButton>
      </HeaderWrapper>
    </>
  );
};

export default CompanyProfileHeader;
