interface IStringIndexed {
  [key: string]: any;
}

export const SortArray = <T extends IStringIndexed>(
  array: Array<T>,
  propertyName: string,
  isAsc?: boolean
) => {
  if (!propertyName) return;

  const ArrayToSort = [...array];
  return ArrayToSort.sort((a, b) => {
    // Check if the property exists and is boolean
    const isBooleanProperty =
      a.hasOwnProperty(propertyName) &&
      typeof a[propertyName] === 'boolean' &&
      b.hasOwnProperty(propertyName) &&
      typeof b[propertyName] === 'boolean';

    if (isBooleanProperty) {
      // Sort based on boolean property
      if (a[propertyName] === b[propertyName]) return 0;
      if (a[propertyName] && !b[propertyName]) return isAsc ? -1 : 1;
      return isAsc ? 1 : -1;
    } else {
      // Sort based on string property
      if (a[propertyName].toLowerCase() < b[propertyName].toLowerCase())
        return isAsc ? -1 : 1;
      if (a[propertyName].toLowerCase() > b[propertyName].toLowerCase())
        return isAsc ? 1 : -1;
      return 0;
    }
  });
};
