import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import {
  NEGOTIATION_FORM_FIELDS,
  NegotiationReviewType,
  REVIEWSTEPENUM,
} from '../../types/review.types';
import { Container, StyledText } from '../../styles/review.styles';
import ReviewQuestion from '../ReviewQuestion';
import { Textarea } from '../../../../shared/components/TextArea';
import ReviewFormButtons from '../ReviewFormButtons';
import LoadingProgress from '../../../../shared/components/LoadingProgress';
import _ from 'underscore';
import ErrorMessage from '../../../../shared/components/ErrorMessage';

type NegotiationReviewProps = {
  submitReviewForm: (
    e: React.FormEvent<HTMLFormElement>,
    formFields: any,
    reviewStep: REVIEWSTEPENUM,
    isDraft?: boolean
  ) => void;
  loading?: boolean;
  rfp: boolean;
  setLoading: (loading: boolean) => void;
  currentReview: NegotiationReviewType;
  isViewMode?: boolean;
  setOpen?: (isOpen: boolean) => void;
};

const NegotiationReview = (props: NegotiationReviewProps) => {
  const {
    submitReviewForm,
    loading,
    currentReview,
    setLoading,
    rfp,
    isViewMode,
    setOpen,
  } = props;
  const [errors, setErrors] = useState<any>({});
  const [formFields, setFormFields] = useState<NegotiationReviewType>(
    currentReview ?? ({} as NegotiationReviewType)
  );

  const handleFormFieldChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const validate = () => {
    const errors: any = {};

    const requiredFields = [
      'negotiationTipsComment',
      'fairnessComment',
      'competitiveBids',
      'discountGiven',
      'fundsReleased',
      'noticeHonored',
      'noticeSelection',
      'procurementFairness',
      'procurementTactics',
      'procurementThreshold',
      'terminationConvenience',
      'forcedTerminationAcceptance',
    ];

    requiredFields.forEach((field) => {
      //@ts-ignore
      if (!formFields[field]) {
        errors[field] = 'This field is required';
      }
    });

    setErrors(errors);
    return !_.isEmpty(errors);
  };

  const submitNegotiationReview = (e: any) => {
    e.preventDefault();
    const hasErrors = validate();

    if (hasErrors) {
      return;
    }
    submitReviewForm(e, formFields, REVIEWSTEPENUM.NEGOTIATION, true);
  };

  useEffect(() => {
    setLoading(true);
    setFormFields(currentReview ?? ({} as NegotiationReviewType));
    setLoading(false);
  }, [currentReview]);

  if (loading) {
    return <LoadingProgress />;
  }

  return (
    <Container>
      <form>
        <Grid container>
          {NEGOTIATION_FORM_FIELDS.map((reviewQuestion) => (
            <Grid item sm={6} xs={12}>
              <ReviewQuestion
                isViewMode={isViewMode}
                key={reviewQuestion.name}
                errors={errors}
                question={reviewQuestion.question}
                options={reviewQuestion.options}
                name={reviewQuestion.name}
                formFields={formFields}
                handleFormFieldChange={handleFormFieldChange}
                isNarrow={reviewQuestion.options.length > 3}
                isWide
              />
            </Grid>
          ))}
        </Grid>

        <Grid container>
          <Grid item xs={12} sm={6}>
            <StyledText>
              Any tips to other sellers to help negotiate with this customer?
            </StyledText>
            <Textarea
              style={{ width: '95%', resize: 'none' }}
              name="negotiationTipsComment"
              onChange={handleFormFieldChange}
              value={formFields.negotiationTipsComment}
              required
              minRows={4}
              readOnly={isViewMode}
            />
            {errors.negotiationTipsComment && (
              <ErrorMessage error={errors.negotiationTipsComment} />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledText style={{ marginLeft: '24px' }}>
              Please add details regarding the procurement process, such as the
              honesty of the buyer, any tactics used, etc.
            </StyledText>
            <Textarea
              style={{ width: '95%', resize: 'none' }}
              name="fairnessComment"
              onChange={handleFormFieldChange}
              value={formFields.fairnessComment}
              minRows={4}
              readOnly={isViewMode}
              required
              spaceleft
            />
            <div style={{ marginLeft: '24px' }}>
              {errors.fairnessComment && (
                <ErrorMessage error={errors.fairnessComment} />
              )}
            </div>
          </Grid>
        </Grid>
        <ReviewFormButtons
          handleContinueReview={(e: React.FormEvent<HTMLFormElement>) =>
            submitNegotiationReview(e)
          }
          submitButtonText="Save Review as Draft"
          nextButtonText={
            rfp
              ? 'Move onto RFP'
              : isViewMode
                ? 'Done'
                : 'Submit Deep Dive Review'
          }
          onSubmit={(e: React.FormEvent<HTMLFormElement>) =>
            isViewMode
              ? setOpen && setOpen(false)
              : submitReviewForm(e, formFields, REVIEWSTEPENUM.NEGOTIATION)
          }
        />
      </form>
    </Container>
  );
};

export default NegotiationReview;
