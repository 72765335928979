import { FormField } from '../../authentication/types/types.auth';

export const UPDATE_ACCOUNT_FORM_FIELDS: Array<FormField> = [
  { name: 'firstName', label: 'First Name', type: 'text', sm: 6, xs: 12 },
  { name: 'lastName', label: 'Last Name', type: 'text', sm: 6, xs: 12 },
  { name: 'email', label: 'Email', type: 'email', xs: 12 },
  { name: 'company', label: 'Current Company', type: 'text', xs: 12 },
  { name: 'positionTitle', label: 'Position Title', type: 'text', xs: 12 },
  { name: 'industry', label: 'Industry', type: 'text', xs: 12 },
  { name: 'companySize', label: 'Company Size', type: 'text', xs: 12 },
];

export const COMPANY_ACCOUNT_FORM_FIELDS: Array<FormField> = [
  { name: 'companyName', label: 'Company Name', type: 'text', sm: 6, xs: 12 },
  { name: 'email', label: 'Email', type: 'email', sm: 6, xs: 12 },
  { name: 'yearFounded', label: 'Year Founded', type: 'text', sm: 6, xs: 12 },
  { name: 'url', label: 'Company Website', type: 'url', sm: 6, xs: 12 },
];
