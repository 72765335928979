import React from 'react';
import { PricingCardWrapper } from '../styles/PricingTable';

interface PricingCardProps {
  children: React.ReactNode;
}

export const PricingCard: React.FC<PricingCardProps> = ({ children }) => {
  return <PricingCardWrapper>{children}</PricingCardWrapper>;
};
