import React from 'react';
import styled from 'styled-components';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

interface ErrorMessageProps {
  error?: string[] | string | undefined | void;
}

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  color: #ff0000;
  font-size: 14px;
  margin: var(--spacing-sm) 0;

  & .icon {
    margin-right: 5px;
  }
`;

const ErrorMessage = ({ error }: ErrorMessageProps) => {
  if (!error || !error.length) return null;

  return (
    <ErrorContainer className="alert alert-danger is-sm">
      <WarningAmberIcon /> &nbsp; {Array.isArray(error) ? error[0] : error}
    </ErrorContainer>
  );
};

export default ErrorMessage;
