import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Container } from '../../shared/components/Container';
import LoadingProgress from '../../shared/components/LoadingProgress';
import Pills from '../../shared/components/Pill';
import CustomSnackbar, {
  SnackBarConfig,
} from '../../shared/components/Snackbar';
import StaticTextCard from '../../shared/components/StaticTextCard';
import { UserContext } from '../../shared/contexts/UserContext';
import { H2, P, screenMdMin } from '../../shared/styles';
import { Company } from '../authentication/types/types.auth';
import CompanySearchInput from '../company-search/components/CompanySearchInput';
import { BasicReviewType } from '../company-search/types/company.types';
import ReviewDialog from '../review/components/ReviewDialog';
import OnboardingList from './components/OnboardingList';
import ReviewTable from './components/ReviewTable';
import { ReviewFilter } from './types/welcome.types';
import { FetchReviewsParams, fetchReviews } from './utils/api';

type WelcomePageProps = {
  setIsNavOpen: (isOpen: boolean) => void;
};

const StyledHeader = styled(H2)`
  text-align: center;
  margin-bottom: var(--spacing-md);
`;

const SearchInputWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const SearchWrapper = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  @media ${screenMdMin} {
    width: 80%;
  }
`;

const StyledTextBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-white);
  border: 1px dashed var(--color-black);
  border-radius: 20px;
  height: 450px;
  padding: var(--spacing-md);
`;

const StyledText = styled(P)`
  font-size: 30px;
  margin-bottom: var(--spacing-md);
`;

const WelcomePage = (props: WelcomePageProps) => {
  const { setIsNavOpen } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState([
    {
      id: 0,
      filterValue: ReviewFilter.LATEST,
      label: 'Latest Reviews',
      isActive: true,
    },
    {
      id: 1,
      filterValue: ReviewFilter.USER,
      label: 'My Reviews',
      isActive: false,
    },
  ]);
  const [reviews, setReviews] = useState<Array<BasicReviewType>>([]);
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState<Array<Company>>([]);
  const [snackbarConfig, setSnackbarConfig] = useState<SnackBarConfig>();
  const [openReviewDialog, setOpenReviewDialog] = useState(false);
  const [searchParams] = useSearchParams();
  const { currentUser } = useContext(UserContext);
  const userVerified = searchParams.get('verified');
  const navigate = useNavigate();

  const handleFilterClicks = (id: number) => {
    setFilters((prevFilters) => {
      return prevFilters.map((filter) => {
        return { ...filter, isActive: filter.id === id };
      });
    });
  };

  const companyOptions =
    companies &&
    companies
      ?.filter((company: Company) => company.isApproved)
      .map((company) => {
        return {
          label: company.companyName,
        };
      });

  const closeReviewDialog = () => setOpenReviewDialog(false);

  const ratingSuccess = () => {
    setOpenReviewDialog(false);
    setSnackbarConfig({
      type: 'success',
      message:
        'Company and Review have been successfully submitted for approval.',
      open: true,
    });
  };

  const handleSnackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const getCompanies = async () => {
    try {
      const searchTerms = {
        searchTerm: searchTerm,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/company/search`,
        searchTerms
      );

      setCompanies(res.data);
    } catch (error) {
      setSnackbarConfig({
        open: true,
        type: 'error',
        message: 'Server error, please try again later',
      });
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const filter = filters.find((filter) => filter.isActive === true);
      const params: FetchReviewsParams = {
        filter: filter?.filterValue || ReviewFilter.LATEST,
        token: currentUser?.idToken || '',
        limit: 10,
      };

      if (filter?.filterValue === ReviewFilter.USER) {
        params.userId = currentUser?.id;
        //@ts-ignore
        params.companyIds = currentUser.Companies.map(
          (company: Company) => company.id
        );
      }
      const reviewsResponse = await fetchReviews(params);

      const uniqueReviews = Array.from(
        new Set(reviewsResponse.map((review: { id: any }) => review.id))
      ).map((id) =>
        reviewsResponse.find((review: { id: unknown }) => review.id === id)
      );

      setReviews(uniqueReviews);

      setLoading(false);
    } catch (error) {
      setSnackbarConfig({
        open: true,
        type: 'error',
        message: 'Server error, please try again later',
      });
      setLoading(false);
    }
    return fetchData;
  };

  useEffect(() => {
    if (userVerified === 'true') {
      setSnackbarConfig({
        message: 'Your account has been successfully verified!',
        open: true,
        type: 'success',
      });
    }
  }, []);

  useEffect(() => {
    if (currentUser?.id) {
      fetchData();
    }
  }, [currentUser, companies]);

  useEffect(() => {
    if (currentUser?.id) {
      getCompanies();
    }
  }, [currentUser, searchTerm, filters]);

  useEffect(() => {
    const companyNames = companies?.map((company) => company.companyName);
    if (companyNames?.includes(searchTerm)) {
      navigate(`/company-search/${searchTerm}`);
    }
  }, [searchTerm]);

  if (!companies) {
    return <LoadingProgress />;
  }

  if (!currentUser) {
    return (
      <StaticTextCard
        header="Not a Member!"
        text="It seems that you are not yet a member of Kandir, please head to our login Page "
        link={true}
      />
    );
  }

  return (
    <Container>
      <SearchWrapper>
        {currentUser.onboardingConfig &&
          currentUser?.onboardingConfig?.length > 0 && (
            <OnboardingList setSnackbarConfig={setSnackbarConfig} />
          )}
        <StyledHeader>Search Companies</StyledHeader>
        <SearchInputWrapper>
          <CompanySearchInput
            handleChange={setSearchTerm}
            options={companyOptions}
            label="Search Companies"
            handleNoOptionClick={() => setOpenReviewDialog(true)}
          />
        </SearchInputWrapper>

        <StyledHeader>My Review Feed</StyledHeader>
        <Pills pills={filters} handleFilterClick={handleFilterClicks} />
        {reviews.length === 0 && (
          <StyledTextBox>
            <StyledText>
              You have not left any reviews or followed any companies yet.
            </StyledText>
            <StyledText>
              Leave a review or follow companies to populate your review feed.
            </StyledText>
          </StyledTextBox>
        )}
      </SearchWrapper>

      {reviews.length > 0 && (
        <SearchWrapper>
          <ReviewTable
            setIsNavOpen={setIsNavOpen}
            allReviews={reviews}
            loading={loading}
            filterType={
              filters.find((filter) => filter.isActive === true)?.filterValue ||
              ReviewFilter.LATEST
            }
          />
        </SearchWrapper>
      )}

      <CustomSnackbar config={snackbarConfig} setOpen={handleSnackBarClose} />
      <ReviewDialog
        open={openReviewDialog}
        setOpen={setOpenReviewDialog}
        cancelRating={closeReviewDialog}
        ratingSuccess={ratingSuccess}
        isAddCompany={true}
      />
    </Container>
  );
};

export default WelcomePage;
