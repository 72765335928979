import React, { ReactNode } from 'react';
import { PricingCardHeaderWrapper } from '../styles/PricingTable';

interface PricingCardHeaderProps {
  children: ReactNode;
}

export const PricingCardHeader: React.FC<PricingCardHeaderProps> = ({
  children,
}) => {
  return <PricingCardHeaderWrapper>{children}</PricingCardHeaderWrapper>;
};
