import * as React from 'react';
import Paper from '@mui/material/Paper';
import { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { P } from '../../../shared/styles';
import CompanyProfileStats from './CompanyProfileStats';
import { Company } from '../../authentication/types/types.auth';
import CompanyProfileAverageRating from './CompanyProfileAverageRating';

type CompanyProfileHeaderProps = {
  currentCompany: Company;
};

const Wrapper = styled.div`
  display: flex;
  padding: var(--spacing-md);
  justify-content: space-between;
`;

const TextImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledImage = styled.img`
  height: 200px;
  width: 200px;
`;

const StyledText = styled(P)`
  margin-top: var(--spacing-md);
`;

const CompanyProfileBanner = (props: CompanyProfileHeaderProps) => {
  const { currentCompany } = props;

  const defaultImage = (ev: SyntheticEvent<HTMLImageElement, Event>) => {
    return ((
      ev.target as HTMLImageElement
    ).src = require('../../../assets/images/stock.jpeg'));
  };

  return (
    <>
      <Paper
        sx={{
          backgroundColor: 'white',
          color: 'black',
        }}
      >
        <Wrapper>
          <TextImageWrapper>
            <StyledImage
              src={`https://kandir-images.s3.eu-west-2.amazonaws.com/Company Logos/${currentCompany?.companyName}.jpeg`}
              onError={defaultImage}
            />
            <StyledText>{currentCompany.profile}</StyledText>

            <CompanyProfileStats
              title={currentCompany.companyName + ' Overview'}
              company={currentCompany}
            />
          </TextImageWrapper>
          <CompanyProfileAverageRating company={currentCompany} />
        </Wrapper>
      </Paper>
    </>
  );
};

export default CompanyProfileBanner;
