import React, { useEffect, useState } from 'react';
import { useDrag, useDrop, DndProvider, DropTargetMonitor } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import styled from 'styled-components';
import { screenMdMin } from '../styles';

const StyledDraggableRow = styled.div<{
  isDragging?: boolean;
  isEven?: boolean;
}>`
  width: 100%;
  opacity: ${({ isDragging }) => (isDragging ? 0.5 : 1)};
  transform: ${({ isDragging }) => (isDragging ? 'scale(1.1)' : 'scale(1)')};
  border: 1px solid #ccc;
  border-radius: var(--border-radius);
  padding: var(--spacing-sm);
  margin-bottom: var(--spacing-xs);
  background-color: ${({ isEven }) =>
    isEven ? '#ccdcf8' : 'var(--color-grey)'};
  @media ${screenMdMin} {
    width: 50%;
  }
`;

export type Item = {
  id: string;
  text: string;
};

type DraggableItemProps = {
  isEven: boolean;
  item: Item;
  index: number;
  moveItem: (fromIndex: number, toIndex: number) => void;
};

type CustomDraggableItemProps = {
  items: Item[];
};

const ItemType = 'ITEM';

const DraggableItem: React.FC<DraggableItemProps> = ({
  item,
  index,
  isEven,
  moveItem,
}) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { index },
  });

  //@ts-ignore
  const [{ isDragging }, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem: { index: number }, monitor: DropTargetMonitor) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <StyledDraggableRow
      isDragging={isDragging}
      ref={(node) => ref(drop(node))}
      isEven={isEven}
    >
      {index + 1 + '. '}
      {item.text}
    </StyledDraggableRow>
  );
};

const DraggableList: React.FC<CustomDraggableItemProps> = ({ items }) => {
  const [list, setList] = useState(items);

  const moveItem = (fromIndex: number, toIndex: number) => {
    const updatedList = [...list];
    const [movedItem] = updatedList.splice(fromIndex, 1);
    updatedList.splice(toIndex, 0, movedItem);
    setList(updatedList);
  };

  useEffect(() => {
    setList(items);
  }, [items]);

  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        {list.map((item, index) => (
          <DraggableItem
            isEven={index % 2 === 0 ? true : false}
            key={item.id}
            item={item}
            index={index}
            moveItem={moveItem}
          />
        ))}
      </DndProvider>
    </div>
  );
};

export default DraggableList;
