import { TextareaAutosize } from '@mui/material';
import styled from 'styled-components';
import { screenMdMin } from '../styles';

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

export const Textarea = styled(TextareaAutosize)<{ spaceleft?: boolean }>`
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${grey[900]};
  background: ${'#fff'};
  border: 1px solid ${grey[200]};
  box-shadow: 0px 2px 2px ${grey[50]};
  &:hover {
    border-color: ${blue[400]};
  }
  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${blue[200]};
  }
  &:focus-visible {
    outline: 0;
  }
  @media ${screenMdMin} {
    margin-left: ${({ spaceleft }) => (spaceleft ? 'var(--spacing-md)' : '0')};
  }
`;

const TextArea = () => {
  return <div>TextArea</div>;
};

export default TextArea;
