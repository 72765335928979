import React, { useContext, useState } from 'react';
import { Box, Button, LinearProgress, TextField } from '@mui/material';
import styled from 'styled-components';
import { UserContext } from '../../../shared/contexts/UserContext';
import CustomSnackbar, {
  SnackBarConfig,
} from '../../../shared/components/Snackbar';
import { showErrorSnackbar } from '../../authentication/utils/auth.utils';

const ButtonWrapper = styled.div`
  text-align: center;
`;

const StyledInput = styled(TextField)`
  margin-bottom: var(--spacing-md);
`;

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState<SnackBarConfig>();

  const { setUserDetails, authenticate } = useContext(UserContext);

  const handleSnackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const onSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    try {
      setLoading(true);
      //@ts-ignore
      setUserDetails().then(({ user, email }) => {
        //@ts-ignore
        authenticate(email, currentPassword).then(() => {
          user.changePassword(
            currentPassword,
            newPassword,
            () => {},
            setLoading(false),
            setSnackbarConfig({
              message: 'Your password has been successfully changed!',
              open: true,
              type: 'success',
            })
          );
        });
      });
    } catch (error) {
      setSnackbarConfig(showErrorSnackbar(error.message));
    }
  };

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <Box sx={{ mt: 3 }}>
      <StyledInput
        fullWidth
        label="Current Password"
        type="password"
        onChange={(e) => setCurrentPassword(e.target.value)}
        required
      />
      <StyledInput
        fullWidth
        type="password"
        label="New Password"
        onChange={(e) => setNewPassword(e.target.value)}
        required
      />
      <ButtonWrapper>
        <Button
          type="submit"
          variant="contained"
          disabled={!currentPassword || !newPassword}
          onClick={onSubmit}
        >
          Change Password
        </Button>
      </ButtonWrapper>
      <CustomSnackbar config={snackbarConfig} setOpen={handleSnackBarClose} />
    </Box>
  );
};

export default ChangePassword;
