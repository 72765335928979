export enum REVIEWSTEPENUM {
  BASIC_REVIEW = 0,
  PURCHASE_DETAILS = 1,
  EVALUATION = 2,
  LEGAL = 3,
  NEGOTIATION = 4,
  RFP = 5,
}

export type PurchaseDetailsReviewType = {
  championEffectiveness?: string;
  definedBudget?: string;
  salesCycleLength?: string;
  buyerFiscalYearEnd?: string;
  paymentCycle?: string;
  economicBuyerName?: string;
  championName?: string;
};

export type EvaluationReviewType = {
  buyerReplacingVendor?: string;
  noDecisionMakers?: string;
  criteriaArticulatedByBuyer?: string;
  criteriaChangeComment?: string;
  securityRequirementsComment?: string;
  criteriaArticulatedByBuyerComment?: string;
  requireTrial?: string;
  trialAccommodated?: string;
  platFormList?: string[];
  durationSecurityReview?: string;
  securityDifficulty?: string;
  submitRoi?: string;
  approversList?: string[];
};

export type LegalReviewType = {
  legalReviewLength?: string;
  legalReviewDifficulty?: string;
  marketingPermission?: string;
  autoRenewalClause?: string;
  paperworkOption?: string;
  customerAskLiability?: string;
  endAskLiability?: string;
  liabilityComment?: string;
  notableGenComment?: string;
};

export type NegotiationReviewType = {
  terminationConvenience?: string;
  forcedTerminationAcceptance?: string;
  noticeHonored?: string;
  noticeSelection?: string;
  procurementThreshold?: string;
  procurementFairness?: string;
  procurementTactics?: string;
  competitiveBids?: string;
  discountGiven?: string;
  fundsReleased?: string;
  negotiationTipsComment?: string;
  fairnessComment?: string;
};

export type RFPReviewType = {
  rfpWin?: string;
  rfpInvolvement?: string;
  rfpInfluence?: string;
  rfpLength?: string;
  accpetanceFormatComment?: string;
  tipsComment?: string;
};

export const BASIC_REVIEW_MAPPINGS = [
  {
    question: 'Which part of the organization did you sell to?',
    options: [
      'Customer Success',
      'Engineering',
      'Finance',
      'HR',
      'Legal',
      'Marketing',
      'Operations',
      'Product',
      'Sales',
      'IT',
      'Security',
    ],
    name: 'dealArea',
  },
  {
    question: 'What was the annual contract value of the deal?',
    options: [
      '0 - 25k',
      `25k - 50k`,
      `50k - 100k`,
      `100k - 250k`,
      `250k - 500k`,
      `500k - 1M`,
      '1MM+',
    ],
    name: 'dealSize',
  },
  {
    question: 'What type of deal is this?',
    options: ['New Business', 'Renewal', 'Expansion/Upsell'],
    name: 'dealType',
  },
  {
    question: 'Term of the deal?',
    options: ['Less than 1 year', '1 Year', '2 Years', '3 Years+'],
    name: 'dealLength',
  },

  {
    question: 'Did you win the deal?',
    options: ['Yes', 'No'],
    name: 'dealClose',
  },
  {
    question: 'Did this deal include an RFP?',
    options: ['Yes', 'No'],
    name: 'dealRfp',
  },
];

export type RATING_FORM_FIELDS_TYPE = {
  transparencyRating: string;
  clarityRating: string;
  understandingRating: string;
  responsivenessRating: string;
};

export const RATING_FORM_FIELDS: RATING_FORM_FIELDS_TYPE = {
  transparencyRating: '',
  clarityRating: '',
  understandingRating: '',
  responsivenessRating: '',
};

export const PURCHASE_DETAILS_FORM_FIELDS = [
  {
    question:
      'On a scale of 1 - 10, how effective was your champion at getting the deal done?',
    options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    name: 'championEffectiveness',
  },
  {
    question: 'Was there a defined budget for this purchase?',
    options: ['Yes', 'No', 'Unknown'],
    name: 'definedBudget',
  },
  {
    question: 'How long was the sales cycle from start to finish?',
    options: [
      '< 1 Month',
      '1 - 3 Months',
      '3 - 6 Months',
      '6 - 12 Months',
      '12+ Months',
    ],
    name: 'salesCycleLength',
  },
  {
    question: "When does the buyer's fiscal year end?",
    options: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
      'Unknown',
    ],
    name: 'buyerFiscalYearEnd',
  },
  {
    question:
      'Did the customer agree to pay monthly, quarterly, annually or other?',
    options: ['Monthly', 'Quarterly', 'Annually', 'Other', 'N/A'],
    name: 'paymentCycle',
  },
];

export const EVALUATION_FORM_FIELDS = [
  {
    question: 'Was the buyer replacing an incumbent vendor?',
    options: ['Yes', 'No', 'Unknown'],
    name: 'buyerReplacingVendor',
  },
  {
    question:
      'Were you required to submit an ROI or other business justification to win this deal?',
    options: ['Yes', 'No', 'N/A'],
    name: 'submitRoi',
  },

  {
    question: 'Did the customer request a trial??',
    options: ['Yes', 'No', 'N/A'],
    name: 'requireTrial',
  },
  {
    question:
      'How many decision makers were involved in the evaluation process?',
    options: ['1', '2-3', '4-5', '5+'],
    name: 'noDecisionMakers',
  },
  {
    question: 'Was a trial accommodated?',
    options: ['Yes', 'No', 'N/A'],
    name: 'trialAccommodated',
  },
  {
    question: 'What was the duration of the security review?',
    options: [
      'Not Required',
      '< Week',
      '2 Weeks',
      '3 Weeks',
      '4+ Weeks',
      'N/A',
    ],
    name: 'durationSecurityReview',
  },
  {
    question: 'Was the evaluation criteria clearly articulated by the buyer?',
    options: ['Yes', 'No'],
    name: 'criteriaArticulatedByBuyer',
  },

  {
    question:
      'On a scale of 1 - 10, how difficult was it to pass the security review?',
    options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'N/A'],
    name: 'securityDifficulty',
  },
];

export const LEGAL_FORM_FIELDS = [
  {
    question: 'What was the duration of the legal review?',
    options: [
      'Not Required',
      '1-2 Weeks',
      '3-4 Weeks',
      '1-3 Months',
      '3-6 Months',
      '>6 Months',
      'N/A',
    ],
    name: 'legalReviewLength',
  },
  {
    question:
      'On a scale of 1 - 10, how difficult was it to pass the legal review?',
    options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'N/A'],
    name: 'legalReviewDifficulty',
  },
  {
    question:
      'Did they give you permission to use their logo and/or name in marketing materials?',
    options: ['Yes', 'No', 'N/A'],
    name: 'marketingPermission',
  },
  {
    question: 'Was there an auto-renewal clause in the contract?',
    options: ['Yes', 'No', 'N/A'],
    name: 'autoRenewalClause',
  },
  {
    question:
      'Did the customer agree to your companies contracting paperwork or did they require use of their own?',
    options: [
      'Our contracting paperwork',
      'Their contracting paperwork',
      'N/A',
    ],
    name: 'paperworkOption',
  },
  {
    question:
      'What did the customer ask for in Limitation of Liability/Indemnification?',
    options: [
      '1-3x ARR',
      '4-5x ARR',
      '6-10x ARR',
      '10-15x ARR',
      '16-20x ARR',
      '20x+ ARR',
      'Unlimited',
      'N/A',
    ],
    name: 'customerAskLiability',
  },
  {
    question:
      'What did you end up with with Limitation of Liability/Indemnification?',
    options: [
      '1-3x ARR',
      '4-5x ARR',
      '6-10x ARR',
      '10-15x ARR',
      '16-20x ARR',
      '20x+ ARR',
      'Unlimited',
      'N/A',
    ],
    name: 'endAskLiability',
  },
];

export const NEGOTIATION_FORM_FIELDS = [
  {
    question: 'Did buyer request a termination for convenience clause?',
    options: ['Yes', 'No', 'N/A'],
    name: 'terminationConvenience',
  },

  {
    question: 'Did you receive notice that you were the selected vendor?',
    options: ['Yes', 'No'],
    name: 'noticeSelection',
  },
  {
    question: 'Did you accept this request?',
    options: ['Yes', 'No', 'N/A'],
    name: 'forcedTerminationAcceptance',
  },
  {
    question: 'Was this notice honored?',
    options: ['Yes', 'No'],
    name: 'noticeHonored',
  },
  {
    question:
      'Did the buyer state a threshold at which procurement would be required to get involved?',
    options: ['Yes', 'No'],
    name: 'procurementThreshold',
  },
  {
    question: 'Did procurement treat you fair and honest?',
    options: ['Yes', 'No'],
    name: 'procurementFairness',
  },
  {
    question:
      'Did procurement/buyer use any tactics to force you to take unfavorable terms and/or additional discounts?',
    options: ['Yes', 'No', 'N/A'],
    name: 'procurementTactics',
  },
  {
    question: 'Did procurement require competitive bids?',
    options: ['Yes', 'No'],
    name: 'competitiveBids',
  },
  {
    question: 'Was a business case required to get funds released?',
    options: ['Yes', 'No', 'N/A'],
    name: 'fundsReleased',
  },
  {
    question: 'What discount did you ultimately give to win the deal?',
    options: [
      '0%',
      '1% - 10%',
      '11% - 20%',
      '21 - 30%',
      '31 - 40%',
      '41 - 50%',
      '51%+',
    ],
    name: 'discountGiven',
  },
];

export const RFP_FORM_FIELDS = [
  {
    question: 'Did you win the RFP?',
    options: ['Yes', 'No'],
    name: 'rfpWin',
  },
  {
    question: 'Were you involved in the RFP process?',
    options: ['Yes', 'No'],
    name: 'rfpInvolvement',
  },
  {
    question: 'Did you have influence over the RFP?',
    options: ['Yes', 'No'],
    name: 'rfpInfluence',
  },
  {
    question: 'How long was the RFP from intiation to final decision?',
    options: ['< 1 Month', '1 - 3 Months', '3 - 6 Months', '6+ Months'],
    name: 'rfpLength',
  },
];
