import styled from 'styled-components';
import { P } from '../../../../shared/styles';

const Container = styled.div`
  width: 100%;
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
`;

const BannerHeader = styled.h3<{ spacetop?: boolean }>`
  text-align: center;
  color: var(--color-white);
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: ${({ spacetop }) => (spacetop ? '40px' : '0')};
  font-size: 18px;

  @media (min-width: 768px) {
    font-size: 32px;
    top: 30%;
    margin-top: ${({ spacetop }) => (spacetop ? '85px' : '0px')};
  }
`;

const BannerText = styled(P)`
  font-size: 12px;
  text-align: center;
  color: var(--color-white);
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (min-width: 768px) {
    font-size: 18px;
    top: 50%;
  }
`;

const Banner = () => {
  return (
    <Container>
      <StyledImage
        src={require('../../../../assets/images/welcomePage/Hero banner.png')}
      />
      <BannerHeader>Bringing transparency to the opaque</BannerHeader>
      <BannerHeader spacetop>world of technology buying</BannerHeader>
      <BannerText>
        A community built entirely by sellers, exclusively for sellers.
      </BannerText>
    </Container>
  );
};

export default Banner;
