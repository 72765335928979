import { Component } from 'react';
import styled from 'styled-components';

interface TitleProps {
  title: string;
}

export const StyledTitle = styled.h1`
  font-size: 36px;
  margin: 0;
  text-align: center;
`;

export class Title extends Component<TitleProps> {
  render() {
    const { title } = this.props;
    return <StyledTitle>{title}</StyledTitle>;
  }
}
