import { Button, Chip, Rating } from '@mui/material';
import { useState, useCallback, SyntheticEvent, useContext } from 'react';
import styled from 'styled-components';
import useWindowResize, {
  Dimensions,
  WindowSizeEnum,
} from '../../../shared/hooks/useWindowResize';
import { Breakpoints, H2, H5, P, screenMdMin } from '../../../shared/styles';
import {
  CardWrapper,
  InnerCardWrapper,
} from '../../../shared/styles/cardStyles/CardStyles';
import { Company } from '../../authentication/types/types.auth';
import { UserContext } from '../../../shared/contexts/UserContext';
import { Link, useNavigate } from 'react-router-dom';

type CompanyCardProps = {
  companyData: Company;
  navToProfile: () => void;
  openReviewDialog: () => void;
};

const StyledLink = styled(Link)`
  text-decoration: none;
  color: var(--color-white);
`;

const ImageWrapper = styled.img`
  border-radius: var(--border-radius);
  font-size: var(--font-size-small);
  margin-top: var(--spacing-md);
  height: 30%;
  width: 35%;
  @media ${screenMdMin} {
    margin-top: 0;
    height: auto;
    min-width: 33%;
    max-height: 95%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: var(--spacing-md);
  width: 100%;
`;

const StyledHeader = styled(H2)`
  margin-bottom: var(--spacing-sm);
  text-decoration: underline;
`;

const StyledSubtitle = styled(H5)`
  font-size: var(--font-size-small);
  @media ${screenMdMin} {
    font-size: var(--font-size-mobile);
    max-width: 75px;
  }
`;

const StyledTextWrapper = styled.div`
  margin-bottom: var(--spacing-sm);
  display: flex;
`;

const StyledChip = styled(Chip)`
  margin-bottom: var(--spacing-sm);
  @media ${screenMdMin} {
    margin-top: var(--spacing-md);
    margin-bottom: 0;
  }
`;

const StyledText = styled(P)`
  font-size: var(--font-size-small);
  @media ${screenMdMin} {
    display: flex;
  }
`;

const CompanyCard = (props: CompanyCardProps) => {
  const { companyData, navToProfile, openReviewDialog } = props;

  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handleReviewClick = () => {
    if (!currentUser) {
      navigate('/sign-up');
    } else {
      openReviewDialog();
    }
  };

  const [screenSize, setScreenSize] = useState<WindowSizeEnum>(
    window.innerWidth > Breakpoints.md
      ? WindowSizeEnum.LARGE
      : WindowSizeEnum.SMALL
  );

  const setSize = useCallback((dimensions: Dimensions) => {
    if (dimensions.width > Breakpoints.md) {
      setScreenSize(WindowSizeEnum.LARGE);
    } else {
      setScreenSize(WindowSizeEnum.SMALL);
    }
  }, []);

  const defaultImage = (ev: SyntheticEvent<HTMLImageElement, Event>) => {
    return ((
      ev.target as HTMLImageElement
    ).src = require('../../../assets/images/stock.jpeg'));
  };

  useWindowResize(setSize);

  return (
    <CardWrapper>
      {screenSize === WindowSizeEnum.LARGE && (
        <ImageWrapper
          src={`https://kandir-images.s3.eu-west-2.amazonaws.com/Company Logos/${companyData.companyName}.jpeg`}
          onError={defaultImage}
        />
      )}
      &nbsp;
      <InnerCardWrapper>
        <StyledHeader>{companyData.companyName}</StyledHeader>
        <StyledTextWrapper>
          <StyledText>{companyData.profile}</StyledText>
        </StyledTextWrapper>
        <StyledTextWrapper>
          <StyledSubtitle bold>No. Of Reviews</StyledSubtitle> &nbsp;
          <StyledChip
            label={(companyData.reviews && companyData.reviews.length) || 0}
          />
        </StyledTextWrapper>
        <StyledTextWrapper>
          <StyledSubtitle bold>Year Founded</StyledSubtitle> &nbsp;
          <StyledChip label={companyData.yearFounded} />
        </StyledTextWrapper>
        <StyledTextWrapper>
          <StyledSubtitle bold>Company Headcount</StyledSubtitle> &nbsp;&nbsp;
          <StyledChip label={companyData.companySize} />
        </StyledTextWrapper>
        {companyData.averageRating && (
          <StyledTextWrapper>
            <StyledSubtitle bold>Average Rating</StyledSubtitle> &nbsp;
            <Rating
              name="read-only"
              value={Number(companyData.averageRating)}
              readOnly
            />
          </StyledTextWrapper>
        )}
        <ButtonWrapper>
          <Button
            variant="contained"
            onClick={navToProfile}
            sx={{ width: '175px', color: 'white' }}
          >
            <StyledLink
              to={`/company-search/${companyData.companyName}`}
              style={{ color: 'white' }}
            >
              Company Profile
            </StyledLink>
          </Button>
          <Button
            variant="contained"
            onClick={handleReviewClick}
            sx={{ width: '175px' }}
            disabled={!currentUser?.id}
          >
            Leave a Review
          </Button>
        </ButtonWrapper>
      </InnerCardWrapper>
      {screenSize === WindowSizeEnum.SMALL && (
        <ImageWrapper
          src={`https://kandir-images.s3.eu-west-2.amazonaws.com/Company Logos/${companyData.companyName}.jpeg`}
          onError={defaultImage}
        />
      )}
    </CardWrapper>
  );
};

export default CompanyCard;
