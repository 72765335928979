import styled from 'styled-components';
import { H4, P, screenMdMin } from '../styles';
import { CardWrapper, InnerCardWrapper } from '../styles/cardStyles/CardStyles';
import { StyledLink } from './Link';

type StaticTextCardProps = {
  header: string;
  text: string;
  link: boolean;
  actionClick?: (open: boolean) => void;
  isCompanyProfile?: boolean;
};

const StyledHeader = styled(H4)`
  margin-bottom: var(--spacing-md);
  text-decoration: underline;
  @media ${screenMdMin} {
    font-size: 18px;
  }
`;
const StyledTextWrapper = styled.div`
  margin-bottom: var(--spacing-sm);
  display: flex;
  font-size: var(--font-size-small);
`;

const StyledText = styled(P)`
  font-size: var(--font-size-mobile);
  @media ${screenMdMin} {
    font-size: var(--font-size-p);
  }
`;

const StaticTextCard = (props: StaticTextCardProps) => {
  const { header, text, link, isCompanyProfile, actionClick } = props;
  return (
    <CardWrapper fullWidth>
      <InnerCardWrapper>
        <StyledHeader>{header}</StyledHeader>
        <StyledTextWrapper>
          <StyledText>
            {isCompanyProfile ? (
              <span>
                Please either leave a{' '}
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => actionClick && actionClick(true)}
                >
                  review
                </span>
                , or come back at a later date.
              </span>
            ) : (
              text
            )}
            {link && <StyledLink to="/sign-in">Login</StyledLink>}
          </StyledText>
        </StyledTextWrapper>
      </InnerCardWrapper>
    </CardWrapper>
  );
};

export default StaticTextCard;
