import * as React from 'react';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Company } from '../../authentication/types/types.auth';
import { H4, P, screenMdMin } from '../../../shared/styles';

type CompanyProfileStatsProps = {
  company: Company;
  title: string;
};

const StyledHeader = styled(H4)`
  text-decoration: underline;
  margin-bottom: var(--spacing-sm);
`;

const ChipsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChipTextWrapper = styled.div`
  display: flex;
  margin-bottom: var(--spacing-md);
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media ${screenMdMin} {
    width: 75%;
    flex-direction: unset;
  }
`;

const StyledText = styled(P)`
  font-weight: 600;
  margin-top: var(--spacing-xs);
`;

const CHIP_MAPPINGS = [
  { name: 'yearFounded', text: 'Year Founded:' },
  { name: 'revenue', text: 'Revenue ($M):' },
  { name: 'companySize', text: 'Company Size:' },
  { name: 'city', text: 'City:' },
  { name: 'state', text: 'State:' },
  { name: 'country', text: 'Country:' },
];

const CompanyProfileStats = (props: CompanyProfileStatsProps) => {
  const { company, title } = props;

  return (
    <Paper
      elevation={0}
      sx={{
        minWidth: '850px',
      }}
    >
      <StyledHeader>{title}</StyledHeader>
      <Wrapper>
        <ChipsContainer>
          {CHIP_MAPPINGS.slice(0, 3).map((chip, i) => {
            return (
              <ChipTextWrapper key={i}>
                <StyledText>{chip.text}</StyledText>
                &nbsp;
                <StyledText>
                  {(company && company[chip.name as keyof Company]) || 'N/A'}
                </StyledText>
              </ChipTextWrapper>
            );
          })}
        </ChipsContainer>
        <ChipsContainer>
          {CHIP_MAPPINGS.slice(3, 6).map((chip, i) => {
            return (
              <ChipTextWrapper key={i}>
                <StyledText>{chip.text}</StyledText>
                &nbsp;
                <StyledText>
                  {(company && company[chip.name as keyof Company]) || 'N/A'}
                </StyledText>
              </ChipTextWrapper>
            );
          })}
        </ChipsContainer>
      </Wrapper>
    </Paper>
  );
};

export default CompanyProfileStats;
