import React, { useContext, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import { H2, P } from '../../../shared/styles';
import { UserContext } from '../../../shared/contexts/UserContext';
import { OnboardingConfigItem } from '../../authentication/types/types.auth';
import { SnackBarConfig } from '../../../shared/components/Snackbar';

type OnboardingListProps = {
  setSnackbarConfig: (config: SnackBarConfig) => void;
};

const StyledTextBox = styled.div`
  width: 100%;
  border-radius: var(--border-radius);
  background-color: var(--color-white);
  padding: var(--spacing-md);
  text-align: center;
`;

const StyledListItem = styled.li<{ removed: boolean }>`
  opacity: ${({ removed }) => (removed ? 0 : 1)};
  transition: opacity 0.5s ease;
`;

const StyledTextContainer = styled.div`
  background-color: #d6ecf3;
  text-align: left;
  margin: var(--spacing-sm);
  padding: var(--spacing-xs);
  border-radius: var(--border-radius);
`;

const StyledHeader = styled(H2)`
  text-align: center;
  margin: auto;
`;

const StyledHeaderWrapper = styled.div`
  display: flex;
`;

const StyledText = styled(P)`
  text-align: center;
  margin: auto;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  float: right;
  font-size: 20px;
`;

const LIST_MAPPINGS = [
  {
    id: 0,
    text: "Kick things off by going to our Company Search, linked here or down below. Look for the publicly traded SaaS technology companies you're interested in learning about.",
  },
  {
    id: 1,
    text: 'Follow companies to get email notifications every time a review is left on that company.',
  },
  {
    id: 2,
    text: 'Leave a review to share your knowledge with the Kandir community and, coming soon, unlock prizes!',
  },
  {
    id: 3,
    text: 'Leaving reviews and following companies will populate your Home Feed below. Sort your feed to find the reviews you are looking for.',
  },
];

const OnboardingList = (props: OnboardingListProps) => {
  const { setSnackbarConfig } = props;
  const { currentUser, setReload, reload } = useContext(UserContext);
  const [removedItemId, setRemovedItemId] = useState<number | null>(null);

  const remainingOnboardingSteps =
    currentUser?.onboardingConfig &&
    currentUser?.onboardingConfig.map((item: OnboardingConfigItem) => {
      const mapping = LIST_MAPPINGS.find((mapping) => mapping.id === item.id);
      return {
        id: item.id,
        text: mapping?.text || '',
      };
    });

  const removeOnboardingItem = async (id: number | null = null) => {
    try {
      let updatedOnboardingConfig;
      if (id === null) {
        updatedOnboardingConfig = [];
      } else {
        setRemovedItemId(id);
        updatedOnboardingConfig =
          currentUser?.onboardingConfig &&
          currentUser?.onboardingConfig.filter(
            (item: OnboardingConfigItem) => item.id !== id
          );
      }
      const updateUserInput = {
        ...currentUser,
        onboardingConfig: updatedOnboardingConfig,
      };

      await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/user`,
        updateUserInput,
        {
          headers: {
            Authorization: currentUser?.idToken,
          },
        }
      );

      setReload(reload + 1);
    } catch (error) {
      setSnackbarConfig({
        type: 'error',
        message: 'An error occurred, please try again later.',
        open: true,
      });
    }
  };

  return (
    <StyledTextBox>
      <StyledHeaderWrapper>
        <StyledHeader>Welcome to Kandir!</StyledHeader>
        <StyledCloseIcon onClick={() => removeOnboardingItem()} />
      </StyledHeaderWrapper>

      <StyledText>
        We couldn't be more excited to have you join our community of
        professional technology sellers. Together we will gather, organize, and
        present information to provide transparency into the world's most
        respected SaaS companies' technology buying processes!
      </StyledText>
      <StyledText>
        Ready to win deals, secure higher price points, and crush your quota?
        Let's get started!
      </StyledText>
      <ul>
        {remainingOnboardingSteps?.map((item: OnboardingConfigItem) => (
          <StyledListItem key={item.id} removed={removedItemId === item.id}>
            <StyledTextContainer>
              {item.text && (
                <>
                  {item.text.includes('linked here') ? (
                    <>
                      {item.text.split('linked here')[0]}
                      <a href="/company-search">linked here</a>
                      {item.text.split('linked here')[1]}
                    </>
                  ) : (
                    item.text
                  )}
                </>
              )}
              <StyledCloseIcon onClick={() => removeOnboardingItem(item.id)} />
            </StyledTextContainer>
          </StyledListItem>
        ))}
      </ul>
      <StyledText>
        That's it! Thank you for being part of the community and getting kandid!
      </StyledText>
    </StyledTextBox>
  );
};

export default OnboardingList;
