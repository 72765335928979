import { useContext, useState } from 'react';
import FormWrapper from './components/AuthFormWrapper.component';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from 'amazon-cognito-identity-js';
import { Container } from '../../shared/components/Container';
import LoadingProgress from '../../shared/components/LoadingProgress';
import CustomSnackbar, {
  SnackBarConfig,
} from '../../shared/components/Snackbar';
import { showErrorSnackbar } from './utils/auth.utils';
import {
  AuthFormFieldsValues,
  UserPoolData,
  AuthEnum,
  SIGN_IN_FORM_FIELDS,
  SIGN_UP_FORM_FIELDS,
  VERIFICATION_FORM_FIELDS,
  User,
  WAITLIST_FORM_FIELDS,
} from './types/types.auth';
import { UserContext } from '../../shared/contexts/UserContext';
import { isEmailRegistered } from './utils/isEmailRegistered';

type OptionType = {
  label: string;
  value: string;
};

const initialFormfields = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  company: '',
  positionTitle: '',
  verificationCode: '',
  industry: '',
  revenue: '',
  companyName: '',
  companySize: '',
};

const userPool = new CognitoUserPool(UserPoolData);

const Authentication = () => {
  const [formFields, setFormFields] = useState<AuthFormFieldsValues | any>(
    initialFormfields
  );
  const [loading, setLoading] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState<SnackBarConfig>();
  const [userNotConfirmed, setUserNotConfirmed] = useState(false);

  const { setCurrentUser, currentUser, setUserDetails } =
    useContext(UserContext);

  const location = useLocation();
  const navigate = useNavigate();

  const user = new CognitoUser({
    Username: formFields.email,
    Pool: new CognitoUserPool(UserPoolData),
  });

  let authType: AuthEnum = location.pathname.includes('verification')
    ? AuthEnum.VERIFICATION
    : location.pathname.includes('sign-up')
      ? AuthEnum.SIGN_UP
      : AuthEnum.SIGN_IN;

  if (location.pathname.includes('waitlist')) {
    authType = AuthEnum.WAITLIST;
  }

  const handleSnackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const handleFormFieldChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    selectedOptions?: any
  ) => {
    const { name, value } = e.target;

    if (selectedOptions) {
      const selectedValues = (selectedOptions as OptionType[]).map(
        (option) => option.value
      );
      setFormFields({ ...formFields, [name]: selectedValues });
    } else {
      setFormFields({ ...formFields, [name]: value });
    }
  };

  const createUser = async (cogId: string, token: string) => {
    const userInput: User = {
      firstName: formFields.firstName,
      lastName: formFields.lastName,
      email: formFields.email,
      company: formFields.company,
      positionTitle: formFields.positionTitle,
      industry: formFields.industry,
      companySize: formFields.companySize,
    };
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/user`, userInput);
    } catch (error) {
      setSnackbarConfig(showErrorSnackbar(error.message));
    }
  };

  const handleSubmitSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const doesUserExist = await isEmailRegistered(formFields.email);
    if (doesUserExist !== null) {
      setSnackbarConfig(
        showErrorSnackbar('An account with the given email already exists')
      );
      setLoading(false);
      return;
    }

    await userPool.signUp(
      formFields.email,
      formFields?.password || '',
      [],
      [],
      (error, data) => {
        if (error) {
          setSnackbarConfig(showErrorSnackbar(error.message));
          setLoading(false);
          return;
        }
        if (data) {
          navigate('/verification');
          setLoading(false);
          return;
        }
      }
    );
  };

  const handleNewPasswordRequired = (cognitoUser: any, newPassword: any) => {
    return new Promise((resolve, reject) => {
      cognitoUser.completeNewPasswordChallenge(
        newPassword,
        {},
        {
          onSuccess: (session: any) => {
            resolve(session);
          },
          onFailure: (err: any) => {
            reject(err);
          },
        }
      );
    });
  };
  const handleSubmitSignIn = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const authDetails = new AuthenticationDetails({
      Username: formFields.email,
      Password: formFields.password,
    });

    try {
      await user.authenticateUser(authDetails, {
        onFailure(error) {
          if (error.message.includes('callback.newPasswordRequired')) {
            handleNewPasswordRequired(user, formFields.password);
          }
          if (error.message && error.message.includes('not confirmed')) {
            setUserNotConfirmed(true);
          } else {
            setSnackbarConfig(showErrorSnackbar(error.message));
          }
          setLoading(false);
        },
        onSuccess(data: any) {
          setCurrentUser({
            email: formFields.email,
            idToken: data.idToken.jwtToken,
          });
          setUserDetails();
          setLoading(false);
          navigate('/welcome');
        },
      });
    } catch (error) {
      setSnackbarConfig(showErrorSnackbar(error.message));
    }
  };

  const handleSubmitVerification = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();

    const authDetails = new AuthenticationDetails({
      Username: formFields.email,
      Password: formFields.password,
    });

    if (!formFields.verificationCode) return;

    setLoading(true);

    await user.confirmRegistration(
      formFields.verificationCode,
      true,
      (error, data) => {
        if (error) {
          setSnackbarConfig(showErrorSnackbar(error.message));
          setLoading(false);
          return;
        }
        if (data) {
          setSnackbarConfig({
            message: 'Account successfully verified!',
            open: true,
            type: 'success',
          });
          user.authenticateUser(authDetails, {
            onFailure(error) {
              setSnackbarConfig(showErrorSnackbar(error.message));
            },
            onSuccess() {
              Auth.currentAuthenticatedUser().then((res) => {
                createUser(
                  res.attributes.sub,
                  res.signInUserSession.idToken.jwtToken
                ).then(() => {
                  setCurrentUser({
                    email: formFields.email,
                    idToken: res.signInUserSession.idToken.jwtToken,
                  });
                  setUserDetails();
                });
              });
            },
          });
        }

        setLoading(false);
        navigate({
          pathname: '/welcome',
          search: `?${createSearchParams({
            verified: 'true',
          })}`,
        });
        return;
      }
    );
  };

  const handleResendVerificationCode = () => {
    setLoading(true);
    user.resendConfirmationCode((error, data) => {
      if (error) {
        setSnackbarConfig(showErrorSnackbar(error.message));
      }
      if (data) {
        setSnackbarConfig({
          message: 'Verification code has been resent to your email!',
          open: true,
          type: 'info',
        });
      }
    });
    setLoading(false);
  };

  const handleJoinWaitlist = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const userInput = {
      firstName: formFields.firstName,
      lastName: formFields.lastName,
      email: formFields.email,
      companiesWorkedWith: formFields.companiesWorkedWith,
    };
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/waiting-list`,
        userInput
      );
      setSnackbarConfig({
        message: 'You have been added to the waitlist!',
        type: 'success',
        open: true,
      });
      setFormFields(initialFormfields);
    } catch (error) {
      setSnackbarConfig({
        message: 'An error has occurred, please try again later',
        type: 'error',
        open: true,
      });
    }
    setLoading(false);
  };

  if (loading) {
    return <LoadingProgress />;
  }

  return (
    <Container>
      {authType === AuthEnum.SIGN_IN && (
        <FormWrapper
          handleFormFieldChange={handleFormFieldChange}
          formFields={SIGN_IN_FORM_FIELDS}
          handleSubmit={handleSubmitSignIn}
          title="Sign In"
          authType={AuthEnum.SIGN_IN}
          defaultValues={formFields}
          buttonText="Sign In"
          userNotConfirmed={userNotConfirmed}
          handleResendVerificationCode={handleResendVerificationCode}
        />
      )}
      {authType === AuthEnum.SIGN_UP && (
        <FormWrapper
          handleFormFieldChange={handleFormFieldChange}
          formFields={SIGN_UP_FORM_FIELDS}
          handleSubmit={handleSubmitSignUp}
          title="Sign Up"
          authType={AuthEnum.SIGN_UP}
          defaultValues={formFields}
          buttonText="Sign Up"
        />
      )}

      {authType === AuthEnum.VERIFICATION && (
        <FormWrapper
          handleFormFieldChange={handleFormFieldChange}
          handleResendVerificationCode={handleResendVerificationCode}
          formFields={VERIFICATION_FORM_FIELDS}
          handleSubmit={handleSubmitVerification}
          title="Verification"
          authType={AuthEnum.VERIFICATION}
          buttonText="Verify"
        />
      )}
      <CustomSnackbar config={snackbarConfig} setOpen={handleSnackBarClose} />
    </Container>
  );
};

export default Authentication;
