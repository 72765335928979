import styled from 'styled-components';

export const StyledPricingTable = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 10px;
  justify-content: center;
  font-family: 'IBM Plex Sans', sans-serif;
`;

export const PricingCardWrapper = styled.div`
  border: 1px solid #000;
  border-radius: 10px;
  width: 300px;
  height: 425px;
  overflow: hidden;
`;

export const PricingCardHeaderWrapper = styled.div`
  background: hsl(199, 40%, 92%);
  padding: 20px;
  text-align: left;
  position: relative;
  height: 225px;
  box-sizing: border-box;
`;

export const PricingCard = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  height: 425px;
  overflow: hidden;
`;

export const FeaturesWrapper = styled.div`
  margin-top: 20px;
  padding: 0 20px;
  flex: 1;
`;

export const FeaturesList = styled.ul`
  list-style-type: none;
  padding: 0;
  text-align: left;
`;

export const Feature = styled.li`
  margin: 10px 0;
  font-size: 16px;
  &:before {
    content: '✔';
    margin-right: 10px;
    color: #00aaff;
  }
`;

export const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-size: 16px;
`;

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  text-align: left;
`;

export const IconWrapper = styled.span`
  margin-right: 10px;
  color: #00aaff;
`;
export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
`;

export const CustomStyledButton = styled.button`
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 0px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
`;
