import axios, { AxiosResponse } from 'axios';

type FilterType = 'companyFollowed' | 'user' | 'date' | 'usersCompanies';

export type FetchReviewsParams = {
  filter: FilterType;
  token: string;
  limit?: number;
  userId?: number;
  companyIds?: number[];
};

export const fetchReviews = async (params: FetchReviewsParams) => {
  const { filter, userId, companyIds, limit, token } = params;
  const url = `${process.env.REACT_APP_API_BASE_URL}/get-reviews`;

  try {
    const response: AxiosResponse = await axios.post(
      url,
      {
        filter,
        limit,
        userId,
        companyIds,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error('Error fetching reviews');
  }
};
