export type Rating = {
  name: string;
  rating: string;
  user: string;
  type: string;
};

export type BasicReviewType = {
  dealSize?: string;
  dealClose?: string;
  dealLength?: string;
  dealType?: string;
  dealArea?: string;
  dealRfp?: string;
  commentBuyerWell?: string;
  commentBuyerBetter?: string;
  commentWinLose?: string;
  commentAdvice?: string;
  id?: number;
  Ratings: Array<Rating>;
  CompanyId?: number;
  UserId?: number;
  companyName?: string;
  createdAt?: Date;
  reviewDepth?: string;
  reviewType?: string;
};

export const RATING_TYPE_OPTIONS = [
  {
    name: 'New business',
  },
  {
    name: 'Renewal',
  },
  {
    name: 'Expansion',
  },
  {
    name: 'Professional Services',
  },
  {
    name: 'Other',
  },
];

export const RATING_DEAL_VALUE_OPTIONS = [
  {
    name: '0k - 10k',
  },
  {
    name: '10k - 25k',
  },
  {
    name: '25k - 50k',
  },
  {
    name: '50k - 100k',
  },
  {
    name: '100k-500k',
  },
  {
    name: '500k - 1MM',
  },
  {
    name: '1MM+',
  },
];

export const RATING_DEAL_LENGTH_OPTIONS = [
  {
    name: '< 1 Year',
  },
  {
    name: '1 Year',
  },
  {
    name: '2 Years',
  },
  {
    name: '3 Years+',
  },
];

export const RATING_RFP_DEAL_LENGTH_OPTIONS = [
  {
    name: '<1 Month',
  },
  {
    name: '1 - 3 Months',
  },
  {
    name: '3 - 6 Months',
  },
  {
    name: '6 - 12 Months',
  },
  {
    name: ' >12 months',
  },
];

export const RATING_DEAL_CLOSE = [
  {
    name: 'Yes',
  },
  {
    name: 'No',
  },
];

export const DEAL_AREA_OPTIONS = [
  {
    name: 'Sales',
  },
  {
    name: 'Marketing',
  },
  {
    name: 'HR',
  },
  {
    name: 'Legal',
  },
  {
    name: 'Operations',
  },
  {
    name: 'Customer Support',
  },
  {
    name: 'Customer Success',
  },
  {
    name: 'Finance',
  },
  {
    name: 'Engineering',
  },
  {
    name: 'Product',
  },
];

export const SELECT_MAPPINGS = [
  {
    label: 'What type of deal?',
    options: RATING_TYPE_OPTIONS,
    name: 'dealType',
  },
  {
    label: 'Term of the deal? ',
    options: RATING_DEAL_LENGTH_OPTIONS,
    name: 'dealLength',
  },
  {
    label: 'What was the size of the deal?',
    options: RATING_DEAL_VALUE_OPTIONS,
    name: 'dealSize',
  },
  {
    label: 'Did you win the deal?',
    options: RATING_DEAL_CLOSE,
    name: 'dealClose',
  },
];

export const RATING_MAPPINGS = [
  {
    label: 'Transparency in Decision Making',
    name: 'transparencyRating',
    responseName: 'transparency',
  },
  {
    label: 'Clarity of Needs',
    name: 'clarityRating',
    responseName: 'clarity',
  },
  {
    label: 'Technical Competence',
    name: 'understandingRating',
    responseName: 'understanding',
  },
  {
    label: 'Responsiveness',
    name: 'responsivenessRating',
    responseName: 'responsiveness',
  },
];

export const READ_RATING_MAPPINGS = [
  {
    label: 'Transparency in Decision Making',
    name: 'transparencyRating',
    textFieldName: 'transparencyComment',
  },
  {
    label: 'Clarity of Needs',
    name: 'clarityRating',
    textFieldName: 'clarityComment',
  },
  {
    label: 'Technical Competence',
    name: 'understandingRating',
    textFieldName: 'understandingComment',
  },
  {
    label: 'Responsiveness',
    name: 'responsivenessRating',
    textFieldName: 'responsivenessComment',
  },
];

export const COMPANY_SIZE_OPTIONS = [
  {
    name: '500 - 1000',
  },
  {
    name: '1,000 - 2,500',
  },
  {
    name: '2,500-  5,000',
  },
  {
    name: '5,000 - 10,000',
  },
  {
    name: '10,000 - 20,000',
  },
  { name: '20,000 - 50,000' },
  { name: '50,000 - 100,000' },
  { name: '100,000 - 200,000' },
  { name: '200,000 - 500,000' },
];

export enum FilterCompanyEnum {
  ALL = 'all',
  MOST_RECENT = 'most_recent',
  MOST_REVIEWED = 'most_reviewed',
}
