import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import {
  REVIEWSTEPENUM,
  RFPReviewType,
  RFP_FORM_FIELDS,
} from '../../types/review.types';
import { Container, StyledText } from '../../styles/review.styles';
import ReviewQuestion from '../ReviewQuestion';
import { Textarea } from '../../../../shared/components/TextArea';
import ReviewFormButtons from '../ReviewFormButtons';
import LoadingProgress from '../../../../shared/components/LoadingProgress';
import _ from 'underscore';
import ErrorMessage from '../../../../shared/components/ErrorMessage';

type RfpReviewProps = {
  submitReviewForm: (
    e: React.FormEvent<HTMLFormElement>,
    formFields: any,
    reviewStep: REVIEWSTEPENUM,
    isDraft?: boolean
  ) => void;
  loading?: boolean;
  setLoading: (loading: boolean) => void;
  currentReview: RFPReviewType;
  isViewMode?: boolean;
  setOpen?: (isOpen: boolean) => void;
};

const RfpReview = (props: RfpReviewProps) => {
  const {
    submitReviewForm,
    loading,
    currentReview,
    setLoading,
    isViewMode,
    setOpen,
  } = props;
  const [errors, setErrors] = useState<any>({});
  const [formFields, setFormFields] = useState<RFPReviewType>(
    currentReview || ({} as RFPReviewType)
  );

  const handleFormFieldChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const validate = () => {
    const errors: any = {};

    const requiredFields = [
      'accpetanceFormatComment',
      'tipsComment',
      'rfpInfluence',
      'rfpInvolvement',
      'rfpLength',
      'rfpWin',
    ];

    requiredFields.forEach((field) => {
      //@ts-ignore
      if (!formFields[field]) {
        errors[field] = 'This field is required';
      }
    });

    setErrors(errors);
    return !_.isEmpty(errors);
  };

  const submitRfpReviewForm = (e: any) => {
    e.preventDefault();
    const hasErrors = validate();

    if (hasErrors) {
      return;
    }
    submitReviewForm(e, formFields, REVIEWSTEPENUM.RFP, true);
  };

  useEffect(() => {
    setLoading(true);
    setFormFields(currentReview ?? ({} as RFPReviewType));
    setLoading(false);
  }, [currentReview]);

  if (loading) {
    return <LoadingProgress />;
  }

  return (
    <Container>
      <form>
        <Grid container>
          {RFP_FORM_FIELDS.map((reviewQuestion) => (
            <Grid item xs={12} sm={6}>
              <ReviewQuestion
                key={reviewQuestion.name}
                isViewMode={isViewMode}
                errors={errors}
                question={reviewQuestion.question}
                options={reviewQuestion.options}
                name={reviewQuestion.name}
                formFields={formFields}
                handleFormFieldChange={handleFormFieldChange}
                isNarrow={reviewQuestion.options.length > 8}
              />
            </Grid>
          ))}
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <StyledText>
              Through what format did the buyer accept submission?
            </StyledText>
            <Textarea
              style={{ resize: 'none' }}
              name="accpetanceFormatComment"
              onChange={handleFormFieldChange}
              minRows={4}
              readOnly={isViewMode}
              required
            />
            {errors.accpetanceFormatComment && (
              <ErrorMessage error={errors.accpetanceFormatComment} />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledText spaceleft>
              Any tips to other sellers to help negotiate with this customer?
            </StyledText>
            <Textarea
              style={{ resize: 'none' }}
              spaceleft
              name="tipsComment"
              onChange={handleFormFieldChange}
              required
              readOnly={isViewMode}
              minRows={4}
            />
            <div style={{ marginLeft: '24px' }}>
              {errors.tipsComment && (
                <ErrorMessage error={errors.tipsComment} />
              )}
            </div>
          </Grid>
        </Grid>
        <ReviewFormButtons
          handleContinueReview={(e: React.FormEvent<HTMLFormElement>) =>
            submitRfpReviewForm(e)
          }
          submitButtonText="Save Review as Draft"
          nextButtonText={isViewMode ? 'Done' : 'Complete Deep Dive Review'}
          onSubmit={(e: React.FormEvent<HTMLFormElement>) =>
            isViewMode
              ? setOpen && setOpen(false)
              : submitReviewForm(e, formFields, REVIEWSTEPENUM.RFP, true)
          }
        />
      </form>
    </Container>
  );
};

export default RfpReview;
