import styled from 'styled-components';

export const Container = styled.div<{ isWhite?: boolean }>`
  padding: var(--spacing-md);
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ isWhite }) =>
    isWhite &&
    `
    background-color: var(--color-white)
  `};
`;
