import React from 'react';
import { List } from '../styles/PricingTable';

interface FeaturesListProps {
  children: React.ReactNode;
}

export const FeaturesList: React.FC<FeaturesListProps> = ({ children }) => {
  return <List>{children}</List>;
};
