import React, { useContext } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import styled from 'styled-components';
import { screenMdMin } from '../../../shared/styles';
import { UserContext } from '../../../shared/contexts/UserContext';

export type MultiSelectOption = {
  label: string;
};

type CompanySearchProps = {
  options?: Array<MultiSelectOption> | any;
  label: string;
  currentValue?: Array<MultiSelectOption> | null;
  handleChange: (value: string) => void;
  handleNoOptionClick?: () => void;
};

const StyledAutoComplete = styled(Autocomplete)`
  width: 300px;
  @media ${screenMdMin} {
    width: 100%;
    margin-bottom: var(--spacing-md);
  }
`;

const StyledNoOptionLinkText = styled.span`
  color: #009edb;
  text-decoration: underline;
  cursor: pointer;
`;

const StyledTextField = styled(TextField)`
  border: 5px #009edb solid;
  border-radius: var(--spacing-sm);
`;

const CompanySearchInput = (props: CompanySearchProps) => {
  const { label, handleChange, options, handleNoOptionClick } = props;

  const { currentUser } = useContext(UserContext);

  return (
    <StyledAutoComplete
      noOptionsText={
        handleNoOptionClick && currentUser?.id ? (
          <div>
            Don't see the company you're looking for?{' '}
            <StyledNoOptionLinkText onClick={handleNoOptionClick}>
              Be the first to review this company here!
            </StyledNoOptionLinkText>
          </div>
        ) : (
          'No options'
        )
      }
      onChange={(e, v: any) => {
        handleChange(v ? v.label : '');
      }}
      options={options}
      renderInput={(params) => (
        <StyledTextField
          onChange={(e) => handleChange(e.target.value)}
          {...params}
          variant="filled"
          label={label}
        />
      )}
    />
  );
};

export default CompanySearchInput;
