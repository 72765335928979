import styled from 'styled-components';
import PreLoginContainer from '../components/PreLoginContainer';
import './TermsOfUse.css';

const StyledLink = styled.a`
  text-decoration: none;
`;

const TermsOfUse = () => {
  return (
    <PreLoginContainer>
      <div class="c17 doc-content">
        <h1 class="c13" id="h.wyd7toxpk1ld">
          <span class="c21">Kandir Terms of Use</span>
        </h1>
        <p class="c8">
          <span class="c1">
            These Terms of Use (the &ldquo;Terms&rdquo;), which incorporate our{' '}
            <StyledLink href="/privacy" target="_blank">
              Privacy Policy
            </StyledLink>
            , govern your access to and use of our services, including our
            various websites, such as Kandir.io, applications, ads,
            communications, events, and other covered products or services that
            link to these Terms (collectively &ldquo;services&rdquo;). By
            accessing and using the services, you agree to comply with these
            Terms. If you&rsquo;re using the services on behalf of a company or
            other legal entity, then &ldquo;you&rdquo; also means such company
            or legal entity and you agree to be bound by these Terms even if we
            have a separate agreement with you. You may not use the services if
            you do not agree to the linked version of the Terms at the time you
            access the services.
            <br />
            For the purposes of these Terms, Kandir means
          </span>
          <span class="c7">&nbsp;</span>
          <span class="c1">Kandir, Inc.</span>
          <span class="c1">
            <br />
            <br />
            THESE TERMS REQUIRE THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO
            RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS, AND ALSO
            LIMIT THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A DISPUTE. SEE
            SECTION 11 FOR ADDITIONAL INFORMATION.
          </span>
        </p>
        <h2 class="c6" id="h.aowh6pvlzy22">
          <span class="c9">1. Eligibility to Use the Services</span>
        </h2>
        <p class="c8">
          <span class="c1">
            To access or use the services, you must be at least 18 years of age
            or the age of majority in your jurisdiction, if older, and not
            prohibited from doing so by applicable law. Except as set forth
            below, or as otherwise approved by us, the services are for your
            personal, non-commercial use unless you enter into a separate
            agreement with us for your commercial use. You may not use the
            services if we&rsquo;ve terminated your account(s) or banned you.
          </span>
        </p>
        <h2 class="c6" id="h.xg14b08g2xqw">
          <span class="c9">2. Kandir Services Accounts</span>
        </h2>
        <h3 class="c4" id="h.kuf7k8e6plmi">
          <span class="c10">2.1 Accounts And Profiles </span>
        </h3>
        <p class="c8">
          <span class="c1">
            You must create an account and provide certain personal data to
            access most of our services.
          </span>
        </p>
        <ul class="c5 lst-kix_le6adhs8ysc7-0 start">
          <li class="c2 li-bullet-0">
            <span class="c1">
              You agree that the information you provide to us via your
              account(s) is accurate and that you will keep it accurate and
              up-to-date at all times.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <span class="c1">
              You&rsquo;re responsible for safeguarding your account(s), and you
              accept responsibility for all activities that occur via your
              account(s). Kandir disclaims any liability for third-party actions
              made via your account(s). You agree to notify us immediately if
              you suspect any unauthorized use of your account(s) or access to
              your password(s).
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <span class="c1">
              In order to provide you with access to features across our
              services, we may create and link different services&rsquo;
              accounts for you.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <span class="c1">
              When you set up an account to access the services, we create a
              profile for you that will include personal data you provide (a
              &ldquo;Profile&rdquo;).
            </span>
          </li>
        </ul>

        <ul class="c5 lst-kix_ozr6ie712f8h-0 start">
          <li class="c2 li-bullet-0">
            <span class="c7">
              We may update your Profile with information we obtain from third
              parties. We may also use personal data you provide to us via other
              services.{' '}
            </span>
            <span class="c7">
              You can read more about how we collect and process your data in
              our{' '}
              <StyledLink href="/privacy" target="_blank">
                Privacy Policy
              </StyledLink>
              .
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <span class="c1">
              We may attempt to verify your ratings, reviews or status through
              various methods, including third party integrations or services.
              We may also utilize signals we receive from your current or former
              employer.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <span class="c1">
              Portions of your Profile may be visible to other users and the
              public.
            </span>
          </li>
        </ul>
        <h3 class="c4" id="h.4kmwamh4xwi">
          <span class="c10">2.2 Communications </span>
        </h3>
        <p class="c8">
          <span class="c1">
            By creating an account, you agree to accept and receive
            communications from Kandir, Kandir affiliates, and third-party
            service providers.
          </span>
        </p>
        <p class="c8">
          <span class="c1">
            For the purposes of these Terms, &ldquo;affiliates&rdquo; means any
            entity that directly or indirectly controls, is controlled by, or is
            under common control of or with Kandir, now or in the future.
            &ldquo;Control&rdquo; for these purposes means having a majority of
            shares or the right and ability to direct management.
          </span>
        </p>
        <p class="c8">
          <span class="c1">
            These communications may be via email, text message, calls, push
            notifications, or otherwise. You understand and agree that you may
            receive communications generated by automatic telephone dialing
            systems delivering pre-recorded messages sent by or on behalf of
            Kandir, its affiliates, and/or our third-party service providers,
            including communications concerning your account(s), our services,
            and services offered by our affiliates. Message and data rates may
            apply.
          </span>
        </p>
        <p class="c8">
          <span class="c1">
            Unless you choose to delete your account(s), you cannot unsubscribe
            from certain communications that are required as part of your use of
            our services (e.g., communications about changes to these Terms.)
          </span>
        </p>
        <h2 class="c6" id="h.a4t6su6nlb2">
          <span class="c9">3. Content on Kandir Services</span>
        </h2>
        <p class="c8">
          <span class="c1">
            &ldquo;Content&rdquo; means any work of authorship or information,
            including photos, logos, advertisements, comments, opinions,
            postings, messages, questions, text, files, images, works of
            authorship, e-mail, data, audio, video, or other materials.
          </span>
        </p>
        <h3 class="c4" id="h.u60h1tz6vngj">
          <span class="c10">3.1 Rights to Your Content</span>
        </h3>
        <p class="c8">
          <span class="c1">
            You are solely responsible for your use of the services and any
            Content you authorize for use on the services, or which is submitted
            via your account (&ldquo;Your Content&rdquo;). <br />
            <br />
            We do not claim ownership in Your Content that you submit or
            authorize for use to the services, but you grant us the rights to
            use Your Content as set forth below. By submitting or authorizing us
            to display Your Content, you hereby grant to us a worldwide,
            unrestricted, irrevocable, perpetual, non-exclusive, fully-paid, and
            royalty-free license (with the right to sublicense through unlimited
            levels of sublicenses) to use, reproduce, copy, process, modify,
            publish, translate, transmit, perform, display, create derivative
            works of, adapt, and distribute Your Content in any and all media
            (now known or later developed) throughout the world and display your
            name, images, likeness, voice, video, and any such other Content
            that you submit, link, or otherwise make available through the
            services, throughout the world in any manner or media, on or off the
            services including for purposes of promoting our services.
          </span>
        </p>
        <ul class="c5 lst-kix_7dul7rkft1zw-0 start">
          <li class="c2 li-bullet-0">
            <span class="c1">
              To the greatest extent permitted by applicable law, you hereby
              expressly waive any and all of your moral rights applicable to
              Kandir&rsquo;s exercise of this license.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <span class="c1">
              You agree that this license includes the right for us to provide,
              promote, and improve the services and to make Content submitted to
              or through the services available to other companies,
              organizations, or individuals for the syndication, broadcast,
              distribution, promotion, or publication of such Content on other
              media and services, subject to our terms and conditions for such
              Content use.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <span class="c1">
              No compensation will be paid with respect to Content that you
              submit through the services.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <span class="c1">
              You should only submit Content to the services that you are
              comfortable sharing with others under these Terms.
            </span>
          </li>
        </ul>
        <p class="c8">
          <span class="c1">
            By submitting ideas, suggestions, documents, feedback, and/or
            proposals, (collectively, &ldquo;Submissions&rdquo;) to Kandir or
            its employees or agents, you acknowledge and agree that Kandir is
            entitled to use, disclose, reproduce, license and otherwise
            distribute, and exploit these Submissions as we see fit, entirely
            without obligation or restriction of any kind on account of
            intellectual property rights or otherwise.
          </span>
        </p>
        <h3 class="c4" id="h.mfj8tky98edu">
          <span class="c10">3.2 Your Limited Rights to Content</span>
        </h3>
        <p class="c8">
          <span class="c1">
            The services contain Content provided by us and our licensors. We
            and our licensors (including other users) own and retain all
            proprietary and intellectual property rights in the Content we each
            provide and Kandir owns and retains all rights in the services. If
            you&rsquo;re an authorized user, we hereby grant you a limited,
            revocable, non-transferable, non-sublicensable license under the
            rights licensable by us to use the services and use Content from our
            services solely for your personal use in connection with the
            services. Except as provided in this section, you agree not to:
          </span>
        </p>
        <ul class="c5 lst-kix_xl6djbdeg4bw-0 start">
          <li class="c2 li-bullet-0">
            <span class="c1">
              reproduce, modify, publish, transmit, distribute, publicly perform
              or display, sell, adapt, or create derivative works based on the
              services or the Content (excluding Your Content); or
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <span class="c1">
              rent, lease, loan, or sell access to the services.
            </span>
          </li>
        </ul>
        <p class="c8">
          <span class="c1">
            The trademarks, logos, and service marks (&ldquo;Marks&rdquo;)
            displayed on the services are our property or the property of third
            parties. You are not permitted to use these Marks without our prior
            written consent or the consent of the third party that owns the
            Mark. Kandir reserves all rights not expressly granted in these
            Terms.
          </span>
        </p>
        <h3 class="c4" id="h.f8a4r5phpqtf">
          <span class="c10">3.3 Content on the Services </span>
        </h3>
        <p class="c8">
          <span class="c1">
            Content from Kandir, as well as users, advertisers, and other third
            parties is made available to you through the services. . You
            understand and agree that:
          </span>
        </p>
        <ul class="c5 lst-kix_7jdypuvse7lg-0 start">
          <li class="c0 li-bullet-0">
            <span class="c1">
              The services may contain or link to sponsored third-party Content
              or ads. The type, degree, and targeting of ads are subject to
              change, and you acknowledge and agree that we may place ads in
              connection with the display of any Content or information on the
              services, including Your Content.
            </span>
          </li>
          <li class="c0 li-bullet-0">
            <span class="c1">
              We are not responsible for, and do not endorse, any third-party
              Content, including linked content, ads and information about
              third-party products and services, job ads, or the opinions of any
              third party, including panelists, group leaders, or other users;
            </span>
          </li>
          <li class="c0 li-bullet-0">
            <span class="c1">
              We make no guarantees about the accuracy, currency, suitability,
              reliability, or quality of the information or Content on or linked
              to via our services; and
            </span>
          </li>
          <li class="c0 li-bullet-0">
            <span class="c1">
              We assume no responsibility for unintended, objectionable,
              inaccurate, misleading, or unlawful Content made available by
              users, advertisers, and third parties.
            </span>
          </li>
        </ul>
        <p class="c8">
          <span class="c1">
            In accordance with Section 230 of the U.S. Communications Decency
            Act, and any equivalent or similar laws in other jurisdictions which
            are intended to exclude or limit the liability of online service
            providers who provide access to user-generated content, we generally
            cannot be held liable for claims arising from the Content provided
            by third parties on the services.
          </span>
        </p>
        <h2 class="c6" id="h.4wiu4aty6rjt">
          <span class="c9">4. Your Use of the Services</span>
        </h2>
        <h3 class="c4" id="h.m3tbsumc27os">
          <span class="c10">4.1 Using Kandir Services</span>
        </h3>
        <p class="c8">
          <span class="c7">Kandir</span>
          <span class="c1">
            &nbsp;offers a variety of services that may change from time to
            time, at our sole discretion.
          </span>
        </p>
        <ul class="c5 lst-kix_6eyucy3vp0nq-0 start">
          <li class="c2 li-bullet-0">
            <span class="c7">
              Whether or not you&rsquo;ve set up an account on our services, if
              you use our services we may process your personal data.{' '}
            </span>
            <span class="c7">
              We describe how we may use your personal data in our{' '}
              <StyledLink href="/privacy" target="_blank">
                Privacy Policy
              </StyledLink>
            </span>
            <span class="c1">
              . This includes personal data we use to offer or provide our
              services to you, our customers, other users, and the public, as
              well as to manage and improve our services. We also may collect
              and share personal data with third party service providers acting
              on our behalf, including providers of analytics services that may
              record or log your activities on our services, including text you
              enter, pages you visit, and features you interact with. By using
              our services, you agree to this data processing and sharing.
            </span>
          </li>
        </ul>
        <h3 class="c4" id="h.x1v2f02edafc">
          <span class="c10">4.2 Anonymity And Identity On Kandir</span>
        </h3>
        <h3 class="c4 c15" id="h.aheb73dnr199">
          <span class="c10"></span>
        </h3>
        <h3 class="c4" id="h.93jeshgdojdy">
          <span class="c1">
            We endeavor to offer opportunities to use our services while
            preserving your anonymity, However, you acknowledge that Kandir
            cannot guarantee your anonymity as, depending on your specific
            situation and circumstances and the contents of your Content, even
            semi-/anonymous identifiers, such as company or job title,
            especially when paired with other information, may allow someone to
            identify you or narrow down your identity to a small group of people
            (e.g., employees in a particular department at your company). You
            should understand this risk before submitting Content to the
            services.
          </span>
        </h3>
        <p class="c18">
          <span class="c12"></span>
        </p>
        <h3 class="c4" id="h.v9mni2wk0br">
          <span class="c10">4.3 House Rules </span>
        </h3>
        <p class="c8">
          <span class="c1">
            You represent and warrant that you will use the services solely for
            lawful purposes in a manner consistent with these Terms and any and
            all applicable laws, regulations, or other legally enforceable
            obligations (including contractual obligations) you may have towards
            us and/or any third parties.
          </span>
        </p>
        <ul class="c5 lst-kix_t4pkhtv2p2en-0 start">
          <li class="c2 li-bullet-0">
            <span class="c1">
              You are solely responsible for your use of the services and Your
              Content.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <span class="c1">
              You agree that by submitting or authorizing Your Content for use
              on the services, you have reviewed,understood, and agreed to
              adhere to our Terms.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <span class="c1">
              You understand that you may expose yourself to liability if Your
              Content or use of the services violates applicable law or any
              third-party right.
            </span>
          </li>
        </ul>
        <p class="c8">
          <span class="c1">You agree that you will not:</span>
        </p>
        <ul class="c5 lst-kix_ey7j9ufh8awa-0 start">
          <li class="c0 li-bullet-0">
            <span class="c1">
              Impersonate another person, or use their email address or phone
              number, or misrepresent your current or former affiliation with an
              employer;
            </span>
          </li>
          <li class="c0 li-bullet-0">
            <span class="c1">
              Create accounts under false or fraudulent pretenses; create or use
              an account for anyone other than yourself; or create multiple
              active accounts to submit Content or interact with other users;
            </span>
          </li>
          <li class="c0 li-bullet-0">
            <span class="c1">
              Submit Content that you do not own or have the right to submit in
              accordance with the license set forth in these Terms;
            </span>
          </li>
          <li class="c0 li-bullet-0">
            <span class="c1">
              Violate these Terms, the terms of your agreements with us, or any
              applicable law, rule, or regulation;
            </span>
          </li>
          <li class="c0 li-bullet-0">
            <span class="c1">
              Submit Content that is defamatory, libelous, or fraudulent; that
              you know to be false or misleading; or that does not reflect your
              honest opinion and authentic experience;
            </span>
          </li>
          <li class="c0 li-bullet-0">
            <span class="c1">
              Act in a manner that is harassing, trolling, threatening, abusive,
              racist, bigoted, or is otherwise objectionable (as solely
              determined by Kandir);
            </span>
          </li>
          <li class="c0 li-bullet-0">
            <span class="c1">
              Promote, endorse, or further illegal activities;
            </span>
          </li>
          <li class="c0 li-bullet-0">
            <span class="c1">
              Disclose information in violation of any legally enforceable
              confidentiality, non-disclosure, or other contractual restrictions
              or rights of any third party, including any current or former
              employers or potential employers;
            </span>
          </li>
          <li class="c0 li-bullet-0">
            <span class="c1">
              Violate the privacy, publicity, copyright, patent, trademark,
              trade secret, or other intellectual property or proprietary rights
              of any third-party;
            </span>
          </li>
          <li class="c0 li-bullet-0">
            <span class="c1">
              Submit anything pornographic or sexually explicit in nature, or
              engage in the exploitation of persons in a sexual or violent
              manner;
            </span>
          </li>
          <li class="c0 li-bullet-0">
            <span class="c1">
              Solicit or submit minors&rsquo; personal data;
            </span>
          </li>
          <li class="c0 li-bullet-0">
            <span class="c1">
              Except as expressly approved by us or as otherwise approved in
              these Terms, and subject to applicable laws, use the services for
              commercial activities and/or promotions such as contests,
              sweepstakes, barter, pyramid schemes, advertising, affiliate
              links, and other forms of solicitation;
            </span>
          </li>
          <li class="c0 li-bullet-0">
            <span class="c1">
              Imply a Kandir endorsement, sponsorship, or partnership of any
              kind without our express written permission;
            </span>
          </li>
          <li class="c0 li-bullet-0">
            <span class="c1">
              Send messages in violation of the USA CAN-SPAM Act or any other
              applicable anti-spam law;
            </span>
          </li>
          <li class="c0 li-bullet-0">
            <span class="c1">
              Introduce software or automated agents to the services, or access
              the services so as to produce multiple accounts, generate
              automated messages, or to scrape, strip, or mine data from the
              services without our express written permission;
            </span>
          </li>
          <li class="c0 li-bullet-0">
            <span class="c1">
              &ldquo;Frame&rdquo; or &ldquo;mirror&rdquo; or otherwise
              incorporate part of the services into any website, or
              &ldquo;deep-link&rdquo; to any portion of the services without our
              express written permission
            </span>
          </li>
          <li class="c0 li-bullet-0">
            <span class="c1">
              Copy, modify, or create derivative works of the services or any
              Content (excluding Your Content) without our express written
              permission;
            </span>
          </li>
          <li class="c0 li-bullet-0">
            <span class="c1">
              Copy or use the information, Content (excluding Your Content), or
              data on the services in connection with a competitive service, as
              solely determined by Kandir;
            </span>
          </li>
          <li class="c0 li-bullet-0">
            <span class="c1">
              Sell, resell, rent, lease, loan, trade, or otherwise monetize
              access to the services or any Content (excluding Your Content)
              without our express written permission;
            </span>
          </li>
          <li class="c0 li-bullet-0">
            <span class="c1">
              Interfere with, disrupt, modify, reverse engineer, or decompile
              any data or functionality of the services;
            </span>
          </li>
          <li class="c0 li-bullet-0">
            <span class="c1">
              Interfere with, disrupt, or create an undue burden on the services
              or the networks or services connected to the services; and/or
            </span>
          </li>
          <li class="c0 li-bullet-0">
            <span class="c1">
              Introduce any viruses, trojan horses, worms, time bombs,
              cancelbots, corrupted files, or similar software to the services;
              or attempt to circumvent any security feature of the services.
            </span>
          </li>
        </ul>
        <h3 class="c4" id="h.kwcdlla4j6p1">
          <span class="c10">4.4 Actions We May Take</span>
        </h3>
        <p class="c8">
          <span class="c1">We may, at our sole discretion:</span>
        </p>
        <ul class="c5 lst-kix_idayzjaxbnna-0 start">
          <li class="c2 li-bullet-0">
            <span class="c1">
              Stop (permanently or temporarily) providing the services or any
              features within the services to you or to users generally.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <span class="c1">
              Create limits on use and storage at our sole discretion at any
              time.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <span class="c1">
              Remove, refuse to distribute, or limit distribution or visibility
              of any Content on the services.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <span class="c1">Suspend or terminate users.</span>
          </li>
        </ul>
        <h2 class="c6" id="h.n965gywsw6c">
          <span class="c9">5. Enforcement by Kandir or Third Parties</span>
        </h2>
        <h3 class="c4" id="h.78h8eejmo5k9">
          <span class="c10">5.1 Removal of Content </span>
        </h3>
        <p class="c8">
          <span class="c7">
            While we have no obligation to do so, we reserve the right to review
            and remove any Content (or portion thereof) that we believe, in our
            sole discretion, violates these Terms or other applicable policies
            linked on the services (including our Community Guidelines), or that
            we deem, in our sole discretion, inappropriate. If you see any
            Content on the services that you believe violates our policies, you
            may report that Content by emailing{' '}
          </span>
          <span class="c7">support@kandir.io</span>
          <span class="c1">
            . Once notified, we will review the Content and consider whether to
            remove it (or a portion thereof). Please note: our interpretation of
            our policies and the decision whether or not to remove Content is
            within our sole discretion. You understand and agree that if we
            choose not to remove or edit Content that you find objectionable,
            that decision will not constitute a violation of these Terms or any
            agreement we have with you.
          </span>
        </p>
        <h3 class="c4" id="h.6haq4jv5cs84">
          <span class="c10">5.2 Other Enforcement Actions </span>
        </h3>
        <p class="c8">
          <span class="c1">
            While we have no obligation to do so, we reserve the right to
            investigate and take appropriate action in our sole discretion
            against you if you violate these Terms, including without
            limitation: removing Content (or portions thereof) from the
            services; suspending some or all of your rights to use the services;
            terminating your account(s); reporting you to law enforcement,
            regulatory authorities, or administrative bodies; and taking legal
            action against you.
          </span>
        </p>
        <h3 class="c4" id="h.m2i8ea8bpx3o">
          <span class="c10">
            5.3 Disclosing User Personal Data &amp; Defending our Users
          </span>
        </h3>
        <p class="c8">
          <span class="c1">
            You understand and agree that in certain circumstances we may
            disclose users&rsquo; personal data to third parties, such as when
            we are required to by law or valid legal process. While we have no
            obligation to do so, we reserve the right, to the fullest extent
            permitted by applicable law, to take appropriate action to protect
            the anonymity of our users against the enforcement of subpoenas or
            other information requests that seek a user&rsquo;s identifying
            information or personal data. We make no guarantee or representation
            that we will seek to protect user anonymity or, if we elect to do
            so, that we will be successful.
          </span>
        </p>
        <h2 class="c6" id="h.41ixgtt5yu0b">
          <span class="c9">6. Indemnity</span>
        </h2>
        <p class="c8">
          <span class="c1">
            You agree to defend, indemnify, and hold us and our affiliates and
            subsidiaries and our and their respective officers, directors, board
            members, board advisors, employees, partners, agents successors and
            assigns (collectively, the &ldquo;Kandir Group&rdquo;) harmless from
            any loss, liability, claim, or demand, including reasonable
            attorneys&rsquo; fees and costs, made by any third party due to or
            otherwise arising from your use of the services, including due to or
            arising from your breach of any provision of these Terms.
          </span>
        </p>
        <h2 class="c6" id="h.o497oxjn2u7e">
          <span class="c9">7. Disclaimers and Limitation on Liability</span>
        </h2>
        <p class="c8">
          <span class="c1">
            The disclaimers and limitations on liability in this section apply
            to the maximum extent allowable under applicable law. Nothing in
            this section is intended to limit any rights you have which may not
            be lawfully limited.
          </span>
        </p>
        <p class="c8">
          <span class="c1">
            Any communications, including emails, texts, or notifications or
            messages corresponding with any activity on the services or any
            other communications service, product, or feature provided on or
            through the services, are provided solely as a courtesy. Kandir
            disclaims all warranties with regard to the transmission or storage
            of these courtesy notices, does not guarantee their delivery or
            receipt, and does not guarantee the date or time at which they may
            be sent or received. In the event a message being sent is intended
            for a closed account, these messages will not be deliverable.
          </span>
        </p>
        <p class="c8">
          <span class="c1">
            Kandir assumes no responsibility and disclaims all liability for the
            content, accuracy, completeness, legality, reliability, or
            availability of any information or Content you submit, send, view,
            or receive through the services.
          </span>
        </p>
        <p class="c8">
          <span class="c1">
            You are solely responsible for your interactions with other users
            and we are not responsible for their activities, omissions, or other
            conduct, whether online or offline. We are not responsible for any
            incorrect, inaccurate, or unlawful Content (including any
            information in Profiles) submitted to the services, whether caused
            by users or by any of the equipment or programming associated with
            or utilized in the services. We assume no responsibility for any
            error, omission, interruption, deletion, defect, delay in operation
            or transmission, communications line failure, theft or destruction
            or unauthorized access to, or alteration of, any communication with
            other users. We are not responsible for any problems or technical
            malfunction of any hardware and software due to technical problems
            on the Internet or on the services or combination thereof, including
            any injury or damage to users or to any person&rsquo;s device
            related to or resulting from participation or downloading materials
            in connection with the services. Under no circumstances shall we be
            responsible for any loss or damage resulting from the use of the
            services or from any Content submitted to the services or
            transmitted to users, or any interactions between users of the
            services, whether online or offline.
          </span>
        </p>
        <p class="c8">
          <span class="c1">
            THE SERVICES ARE PROVIDED &ldquo;AS-IS&rdquo; AND AS AVAILABLE. WE
            EXPRESSLY DISCLAIM ANY WARRANTIES AND CONDITIONS OF ANY KIND,
            WHETHER EXPRESS OR IMPLIED, INCLUDING THE WARRANTIES OR CONDITIONS
            OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET
            ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. WE MAKE NO WARRANTY THAT:
            (1) THE SERVICES WILL MEET YOUR REQUIREMENTS; (2) THE SERVICES WILL
            BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE
            BASIS; OR (3) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE
            SERVICES WILL BE ACCURATE OR RELIABLE.
          </span>
        </p>
        <p class="c8">
          <span class="c1">
            YOU HEREBY RELEASE THE KANDIR GROUP FROM ANY AND ALL CLAIMS,
            DEMANDS, AND LOSSES, DAMAGES, RIGHTS, CLAIMS, AND ACTIONS OF ANY
            KIND THAT ARE EITHER DIRECTLY OR INDIRECTLY RELATED TO OR ARISES
            FROM: (1) THE ACTIONS, CONTENT, OR DATA OF THIRD PARTIES (INCLUDING,
            ADVERTISERS, EMPLOYERS, AND OTHER USERS) (2) YOUR PARTICIPATION IN
            ANY OFFLINE EVENTS.
          </span>
        </p>
        <p class="c8">
          <span class="c1">
            Kandir reserves the right in its sole discretion to review, improve,
            modify, or discontinue, temporarily or permanently, the services
            and/or any features, information, services, materials, Content, or
            information on the services with or without notice to you. You agree
            that Kandir will not be liable to you or any third party for any
            modification or discontinuance of the service or any portion
            thereof.
          </span>
        </p>
        <p class="c8">
          <span class="c1">
            IN NO EVENT SHALL THE KANDIR GROUP BE LIABLE TO YOU OR ANY THIRD
            PARTY FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY,
            INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES ARISING FROM YOUR USE OF
            THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED IN
            THESE TERMS, WHERE PERMITTED BY APPLICABLE LAW, YOU AGREE THAT THE
            KANDIR GROUP&rsquo;S LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM
            OR RELATED TO YOUR USE OF THE SERVICES (FOR ANY CAUSE WHATSOEVER AND
            REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE LIMITED
            TO ONE HUNDRED U.S. DOLLARS ($100).
          </span>
        </p>
        <p class="c8">
          <span class="c1">
            You acknowledge that you are familiar with the provisions of Section
            1542 of the California Civil Code (or similar applicable law in your
            jurisdiction), which provides as follows: &ldquo;A GENERAL RELEASE
            DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR
            SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE
            RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED
            HIS OR HER SETTLEMENT WITH THE DEBTOR.&rdquo; You hereby expressly
            waive and relinquish all rights and benefits under Section 1542 of
            the California Civil Code and any law or legal principle of similar
            effect in any jurisdiction with respect to the releases and/or
            discharges granted in these Terms, including but not limited to the
            releases and/or discharges of unknown claims.
          </span>
        </p>
        <h2 class="c6" id="h.30y1d2kha8pd">
          <span class="c9">8. Termination</span>
        </h2>
        <p class="c8">
          <span class="c1">
            These Terms remain in effect while you use the services and, for
            registered users, as long as your account(s) remains open. You may
            delete your account(s) at any time. We may suspend or terminate your
            account(s) or your access to parts of the services, for any or no
            reason, without notice to you. We will have no liability whatsoever
            to you for any termination of your account(s) or related deletion of
            your data.
          </span>
        </p>
        <p class="c8">
          <span class="c1">
            All provisions of these Terms shall survive termination or
            expiration of these Terms except those provisions granting access to
            or use of the services. For the avoidance of doubt, you agree that
            these Terms apply to your use of the services and any Content
            submitted on the services at any time prior to the termination or
            expiration of these Terms.
          </span>
        </p>
        <h2 class="c6" id="h.wponjyao7q27">
          <span class="c9">9. Changes to These Terms</span>
        </h2>
        <p class="c8">
          <span class="c1">
            We may revise these Terms from time to time by posting an updated
            version available via a link on the services. If we make a change
            that we believe materially reduces your rights or increases your
            responsibilities, we will notify you by email. We may provide notice
            of changes in other circumstances as well. Any such changes will not
            apply to any claim brought prior to the effective date of the
            revised Terms incorporating such changes. We encourage you to
            periodically review this page for the latest information on our
            Terms. Your continued use of the services is subject to the most
            current effective version of these Terms.
          </span>
        </p>
        <h2 class="c6" id="h.9oqe294tqx80">
          <span class="c9">
            10. Pre-Suit Discovery &amp; Analogous Procedures Or Applications
          </span>
        </h2>
        <p class="c8">
          <span class="c1">
            You agree to waive your right to file a pre-suit discovery
            proceeding (or any equivalent procedure in jurisdictions other than
            the U.S.) seeking a user&rsquo;s identifying information from
            Kandir.
          </span>
        </p>
        <h2 class="c6" id="h.iz7cn0dipvm8">
          <span class="c9">11. Dispute Resolution</span>
        </h2>
        <p class="c8">
          <span class="c1">
            PLEASE READ THIS SECTION 11 CAREFULLY AS IT AFFECTS YOUR RIGHTS.
            UNLESS YOU OPT OUT OF ARBITRATION PURSUANT TO SECTION 11.2.3 BELOW,
            YOU AGREE THAT BY ENTERING INTO THESE TERMS, YOU AND KANDIR ARE EACH
            WAIVING THE RIGHT TO TRIAL BY JURY OR TO PARTICIPATE IN A CLASS
            ACTION. YOU AND KANDIR AGREE THAT EACH PARTY MAY BRING CLAIMS
            AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT
            AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
            REPRESENTATIVE PROCEEDING. ANY ARBITRATION WILL TAKE PLACE ON AN
            INDIVIDUAL BASIS; CLASS ARBITRATIONS ARE NOT PERMITTED.
          </span>
        </p>
        <h3 class="c4" id="h.f6hnfbad4mhm">
          <span class="c10">11.1 Informal Dispute Resolution</span>
        </h3>
        <ul class="c5 lst-kix_uw0onryuv45u-0 start">
          <li class="c2 li-bullet-0">
            <span class="c1">
              If either of us intends to seek arbitration under this section,
              the party seeking arbitration must first notify the other party of
              the dispute in writing at least 30 days in advance of initiating
              arbitration. You and we agree that the opportunity to meet and
              confer informally is a material term and a material reason we are
              offering this service and therefore you agree you will abide by
              it.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <span class="c7">
              Notice to Kandir should be sent to Kandir Inc., 221 River Road,
              Saunderstown RI 02874.
            </span>
            <span class="c1">
              &nbsp;If you have an account with our services, we will provide
              notice by means of a communication to the email address associated
              with your account.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <span class="c1">
              Any notice of dispute (&ldquo;Notice&rdquo;) must (1) describe the
              nature and basis of the claim or dispute; and (2) set forth the
              specific relief sought.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <span class="c1">
              During the 30 day period, you and we agree to act in good faith to
              resolve the dispute. If you and Kandir cannot reach an agreement
              to resolve the claim within 30 days after the Notice is received,
              you or Kandir may commence formal proceedings in accordance with
              section 11.2.
            </span>
          </li>
        </ul>
        <h3 class="c4" id="h.yko1mozdth03">
          <span class="c10">
            11.2 Binding Arbitration Agreement, Exceptions to Arbitration,
            Governing Law &amp; Venue
          </span>
        </h3>
        <h4 class="c20" id="h.wk081rnondpe">
          <span class="c11 c7">
            11.2.1 Agreement to Arbitrate and Class Action/Class Arbitration
            Waiver.
          </span>
        </h4>
        <p class="c8">
          <span class="c7">Y</span>
          <span class="c1">
            ou and Kandir each agree that any and all disputes between Kandir
            and its users arising under or related in any way to these Terms
            and/or your use of our services (and which are not otherwise
            resolved pursuant to section 11.1) must be resolved through binding
            arbitration as described in section 11.2. If an arbitrator or court
            decides that any part of this agreement to arbitrate set forth in
            section 11.2 is unenforceable, the remainder of section 11 of these
            Terms will nevertheless still apply (including the prohibition on
            class arbitration).
          </span>
        </p>
        <h4 class="c20" id="h.uzjjykerf3nr">
          <span class="c11 c7">11.2.2 Arbitration Procedure. </span>
        </h4>
        <ul class="c5 lst-kix_6ha72wuvw2ck-0 start">
          <li class="c2 li-bullet-0">
            <span class="c1">
              Any arbitration will be governed by the Consumer Arbitration Rules
              of the American Arbitration Association (&ldquo;AAA&rdquo;), if
              applicable, as modified by this section. The AAA&rsquo;s rules and
              a form for initiating the proceeding are available at www.adr.org.
              Any settlement offer made by you or Kandir shall not be disclosed
              to the arbitrator.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <span class="c7">
              Unless otherwise required by the applicable arbitration rules, the
              arbitration shall be held in
            </span>
            <span class="c1">&nbsp;the state of Rhode Island.</span>
          </li>
          <li class="c2 li-bullet-0">
            <span class="c1">
              For any claim where the total amount of the award sought is
              $10,000 or less, you and Kandir may elect to have the arbitration
              conducted by audio and/or a video communication system (including,
              but not limited to telephone conference or a video conference
              platform such as Zoom) or based solely on written submissions,
              which election shall be binding on you and Kandir subject to the
              arbitrator&rsquo;s discretion to require an in-person hearing. In
              cases where an in-person hearing is held, you or Kandir may attend
              by audio and/or a video communication system (including, but not
              limited to telephone conference or a video conference platform
              such as Zoom), unless the arbitrator requires otherwise.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <span class="c1">
              The arbitrator will decide the substance of all claims in
              accordance with applicable law, including recognized principles of
              equity, and will honor all claims of privilege recognized by law.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <span class="c1">
              The arbitrator shall not be bound by rulings in prior arbitrations
              involving different users, but is bound by rulings in prior
              arbitrations involving the same user to the extent required by
              applicable law.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <span class="c1">
              The arbitrator&rsquo;s award shall be final and binding and
              judgment on the award rendered by the arbitrator may be entered in
              any court having jurisdiction thereof.
            </span>
          </li>
        </ul>
        <h4 class="c20" id="h.vfwceiarjlhp">
          <span class="c11 c7">11.2.3 Exceptions to Arbitration. </span>
        </h4>
        <p class="c8">
          <span class="c1">
            The agreement to arbitration provisions set forth in subparts 11.2.1
            and 11.2.2 above will not apply to the following:
          </span>
        </p>
        <ul class="c5 lst-kix_6tm8d8rooleh-0 start">
          <li class="c0 li-bullet-0">
            <span class="c1">
              small claims court cases that qualify for small claims status;
            </span>
          </li>
          <li class="c0 li-bullet-0">
            <span class="c1">
              legal proceedings or procedures that involve efforts to obtain
              personal data related to a user or users, including but not
              limited to the author of any Kandir Content or review;
            </span>
          </li>
          <li class="c0 li-bullet-0">
            <span class="c1">
              any legal proceedings brought against any of the Kandir Group by
              companies or other legal entities, or individuals acting on behalf
              of such companies or other legal entities;
            </span>
          </li>
          <li class="c0 li-bullet-0">
            <span class="c1">
              any legal proceedings brought by any of the Kandir Group against
              companies or other legal entities, or individuals acting on behalf
              of any such companies or other legal entities; and
            </span>
          </li>
          <li class="c0 li-bullet-0">
            <span class="c1">
              a party&rsquo;s right to seek injunctive or other equitable relief
              in a court of competent jurisdiction to prevent the actual or
              threatened infringement, misappropriation or violation of a
              party&rsquo;s copyrights, trademarks, trade secrets, patents, or
              other intellectual property rights.
            </span>
          </li>
          <li class="c0 li-bullet-0">
            <span class="c1">
              any claim for public injunctive relief, which must be adjudicated
              in court, not by the arbitrator. If either party seeks public
              injunctive relief, that request for relief shall be severed from
              any arbitration proceeding and stayed pending a final resolution
              of the arbitration, at which point the claim may be adjudicated.
            </span>
          </li>
        </ul>
        <p class="c8">
          <span class="c1">
            If, for some reason, the prohibition on class arbitrations set forth
            in section 11 cannot be enforced, then the entirety of the agreement
            to arbitrate set forth in subparts 11.2.1-3 will not apply; the
            remainder of section 11 will continue to apply.
          </span>
        </p>
        <h4 class="c20" id="h.js3zo1c0h16h">
          <span class="c7 c11">
            11.2.4 Changes To The Agreement To Arbitrate
          </span>
        </h4>
        <ul class="c5 lst-kix_3k7all3un63j-0 start">
          <li class="c2 li-bullet-0">
            <span class="c1">
              We will notify you of changes to section 11 by posting the changes
              at least 30 days before the effective date of the changes and by
              notice to the email address associated with your account. It is
              therefore essential that you keep your email address up to date.
              You can update your email address from your accounts settings
              page.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <span class="c1">
              If you do not agree to the changed terms, you may close your
              account within the 30 day period and you will not be bound by
              these changes. Otherwise, if you continue to use our services, the
              changed terms will apply to all disputes or claims governed by
              this section 11 that have arisen or may arise between you and
              Kandir which are initiated after the time the changed terms take
              effect.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <span class="c1">
              Notwithstanding any provision in these Terms to the contrary, you
              and we agree that if we make any changes to section 11 (other than
              a change to any referenced notice address or site links) in the
              future, that change will not apply to any claim that was filed in
              a legal proceeding prior to the effective date of the change.
            </span>
          </li>
        </ul>
        <h2 class="c6" id="h.ursj0eavojhu">
          <span class="c9">12. Miscellaneous</span>
        </h2>
        <ul class="c5 lst-kix_agym8qa0kve0-0 start">
          <li class="c2 li-bullet-0">
            <span class="c1">
              Except as specifically stated in another agreement we have with
              you, these Terms, and any other agreements, documents, or policies
              incorporated by reference in these Terms, constitute the entire
              agreement between you and us regarding the use of the services and
              these Terms supersede all prior proposals, negotiations,
              agreements, and understandings concerning the subject matter of
              these Terms.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <span class="c1">
              You represent and warrant that no person has made any promise,
              representation, or warranty, whether express or implied, not
              contained in these Terms to induce you to enter into this
              agreement.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <span class="c1">
              Our failure to exercise or enforce any right or provision of these
              Terms shall not operate as a waiver of such right or provision.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <span class="c1">
              If any provision of the Terms is found to be unenforceable or
              invalid, then only that provision shall be modified to reflect the
              parties&rsquo; intention or eliminated to the minimum extent
              necessary so that the Terms shall otherwise remain in full force
              and effect and enforceable.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <span class="c1">
              To the extent allowed by law, the English language version of
              these Terms are binding and any translations are provided for
              convenience only.
            </span>
          </li>
          <li class="c2 li-bullet-0">
            <span class="c1">
              The Terms, and any rights or obligations hereunder, are not
              assignable, transferable or sublicensable by you except with
              Kandir&rsquo;s prior written consent, but may be assigned or
              transferred by us without restriction. Any attempted assignment by
              you shall violate these Terms and be void. The section titles in
              these Terms are for convenience only and have no legal or
              contractual effect. As used in these Terms, the word
              &ldquo;including&rdquo; means &ldquo;including but not limited
              to.&rdquo;
            </span>
          </li>
        </ul>
        <p class="c8">
          <span class="c7">
            Please contact us with any questions regarding these Terms.
          </span>
        </p>
      </div>
    </PreLoginContainer>
  );
};

export default TermsOfUse;
