import styled from 'styled-components';

const PreLoginContainer = styled.div<{ isWhite?: boolean }>`
  padding: var(--spacing-md);
  display: flex;
  flex-direction: column;
  align-items: left;
  ${({ isWhite }) =>
    isWhite &&
    `
    background-color: var(--color-white)
  `};
`;

export default PreLoginContainer;
