import { PricingTable } from './pricing-table';
import { pricingCards } from './data';
import PreLoginContainer from '../components/PreLoginContainer';
import styled from 'styled-components';
import { H2 } from '../../../shared/styles';

const Pricing = () => {
  return (
    <PreLoginContainer>
      <PricingTable pricingCards={pricingCards} />
    </PreLoginContainer>
  );
};

export default Pricing;
