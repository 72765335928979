import { useCallback, useState } from 'react';

type stateSetter = (newState: unknown) => void;
type stateExtender = (newState: object) => void;

export function useExtendState<T>(
  initialValue: any
): [T, stateExtender, stateSetter] {
  const [state, setState] = useState<T>(initialValue);

  const extendState = useCallback((newState: object) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  }, []);
  //@ts-ignore
  return [state, extendState, setState];
}
