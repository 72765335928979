import React from 'react';
import styled from 'styled-components';

interface TaglineProps {
  tagline: string;
}
export const StyledTagline = styled.div`
  font-size: 18px;
  margin: 5px 0;
  text-align: center;
`;

export const Tagline: React.FC<TaglineProps> = ({ tagline }) => {
  return <StyledTagline className="tagline">{tagline}</StyledTagline>;
};
