import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { UserContext } from '../../../shared/contexts/UserContext';
import { H2, H5, P, screenMdMin } from '../../../shared/styles';
import axios from 'axios';

export enum ReviewDepthEnum {
  BASIC = 'BASIC',
  DEEP_DIVE = 'DEEP_DIVE',
}

type ReviewHeaderProps = {
  userId: number;
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: var(--spacing-md);
  border: 1px solid transparent;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
  @media ${screenMdMin} {
    flex-direction: unset;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: var(--spacing-md);
  @media ${screenMdMin} {
    width: 22%;
  }
`;

const StyledSubtitle = styled(H5)`
  padding-top: 0;
`;

const StyledText = styled(P)`
  margin-top: 2px;
  margin-left: var(--spacing-sm);
`;

const ReviewHeader = (props: ReviewHeaderProps) => {
  const { userId } = props;
  const { currentUser } = useContext(UserContext);
  const [reviewCount, setReviewCount] = useState<number | null>(null);

  useEffect(() => {
    const fetchReviewCount = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/review/${userId}/count`
        );
        setReviewCount(res.data.count);
      } catch (error) {
        setReviewCount(0);
      }
    };

    fetchReviewCount();
  }, [userId]);

  const REVIEW_HEADER_MAPPINGS = [
    {
      text: 'Reviewer Tier:',
      value: 'Bronze',
    },

    {
      text: 'Number of Reviews Provided:',
      value: reviewCount !== null ? reviewCount : 'Loading...',
    },
    {
      text: 'Active Since:',
      value: dayjs(currentUser?.createdAt).format('MM/DD/YYYY') || 0,
    },
    {
      text: 'Size of Organization of User:',
      value: `${currentUser?.companySize || 0} employees`,
    },
  ];

  return (
    <>
      <H2>About the Reviewer</H2>
      <Wrapper>
        {REVIEW_HEADER_MAPPINGS.map((headerValue, index) => {
          return (
            <InfoWrapper key={index}>
              <StyledSubtitle bold>{headerValue.text}</StyledSubtitle>
              <StyledText>{headerValue.value}</StyledText>
            </InfoWrapper>
          );
        })}
      </Wrapper>
    </>
  );
};

export default ReviewHeader;
