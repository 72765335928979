import { BasicReviewType } from '../../company-search/types/company.types';
import { USERTYPENUM } from '../components/UserTypeSelect';

export enum AuthEnum {
  SIGN_IN = 'sign-in',
  SIGN_UP = 'sign-up',
  VERIFICATION = 'verification',
  COMPLETE_REGISTRATION = 'complete-registration',
  WAITLIST = 'waitlist',
}

export type AuthFormFieldsValues = {
  email: string;
  password?: string;
  company: string;
  positionTitle: string;
  firstName: string;
  lastName: string;
  verificationCode?: string;
  industry: string;
  companyName?: string;
  revenue: string;
  address?: string;
  profile?: string;
  yearFounded?: string;
  companySize?: string;
  url?: string;
  imageRef?: string;
};

export type CompleteRegistrationFormFieldsValues = {
  firstName: string;
  lastName: string;
  industry?: string;
};

export type FormFieldsValues =
  | AuthFormFieldsValues
  | CompleteRegistrationFormFieldsValues;

export type FormField = {
  name: string;
  label: string;
  type?: string;
  sm?: number;
  xs?: number;
};

export type OnboardingConfigItem = {
  id: number;
  text?: string;
};

export type User = {
  id?: any;
  email: string;
  company?: string;
  positionTitle?: string;
  industry?: string;
  firstName?: string;
  lastName?: string;
  idToken?: string;
  hasCompletedRegistration?: boolean;
  isPaidMember?: boolean;
  userType?: string;
  Companies?: Array<Company>;
  reviews?: Array<BasicReviewType>;
  companySize?: string;
  createdAt?: Date;
  onboardingConfig?: Array<OnboardingConfigItem>;
};

export type Company = {
  reviewsCount?: number;
  id?: number;
  yearFounded?: string;
  email?: string;
  companyName?: string;
  companySize?: string;
  city?: string;
  profile?: string;
  state?: string;
  url?: string;
  imageRef?: string;
  idToken?: string;
  country?: string;
  hasCompletedRegistration?: boolean;
  isPaidMember?: boolean;
  revenue?: string;
  userType?: USERTYPENUM;
  clarity?: string;
  transparency?: string;
  responsiveness?: string;
  understanding?: string;
  averageRating?: string;
  reviews?: Array<BasicReviewType>;
  createdAt?: Date;
  stockTicker?: string;
  isApproved?: boolean;
};

export type IdTokenData = {
  email: string;
  'cognito:username': string;
};

export const UserPoolData = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID as string,
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID as string,
};

export const SIGN_UP_FORM_FIELDS: Array<FormField> = [
  { name: 'firstName', label: 'First Name', type: 'text', sm: 6, xs: 12 },
  { name: 'lastName', label: 'Last Name', type: 'text', sm: 6, xs: 12 },
  { name: 'email', label: 'Email', type: 'email', xs: 12 },
  { name: 'company', label: 'Current Company', type: 'text', xs: 12 },
  { name: 'positionTitle', label: 'Position Title', type: 'text', xs: 12 },
  { name: 'companySize', label: 'Company Size', type: 'text', xs: 12 },
  { name: 'industry', label: 'Industry', type: 'text', xs: 12 },
  { name: 'password', label: 'Password', type: 'password', xs: 12 },
];

export const WAITLIST_FORM_FIELDS: Array<FormField> = [
  { name: 'firstName', label: 'First Name', type: 'text', sm: 6, xs: 12 },
  { name: 'lastName', label: 'Last Name', type: 'text', sm: 6, xs: 12 },
  { name: 'email', label: 'Email', type: 'email', xs: 12 },
];

export const VERIFICATION_FORM_FIELDS: Array<FormField> = [
  {
    name: 'verificationCode',
    label: 'Verification Code',
    type: 'text',
    xs: 12,
  },
];

export const SIGN_IN_FORM_FIELDS: Array<FormField> = [
  { name: 'email', label: 'Email', type: 'email', xs: 12 },
  { name: 'password', label: 'Password', type: 'password', xs: 12 },
];

export const COMPLETE_REGISTRATION_FORM_FIELDS: Array<FormField> = [
  { name: 'firstName', label: 'First Name', type: 'text', sm: 6, xs: 12 },
  { name: 'lastName', label: 'Last Name', type: 'text', sm: 6, xs: 12 },
  { name: 'company', label: 'Current Company', type: 'text', xs: 12 },
  { name: 'positionTitle', label: 'Position Title', type: 'text', xs: 12 },
];

export const COMPANY_SIZE_OPTIONS = [
  {
    name: 'Under 50',
  },
  {
    name: '51 - 250',
  },
  {
    name: '251 - 1000',
  },
  {
    name: '1001 - 5000',
  },
  {
    name: '5001 - 10,000',
  },
  {
    name: '10, 001 - 50,000',
  },
  {
    name: 'Over 50,000',
  },
];

export const COMPANY_REVENUE_OPTIONS = [
  {
    name: 'Under $500,000',
  },
  {
    name: '$500,000 - $1,000,000',
  },
  {
    name: '$1,000,001 - $5,000,000',
  },
  {
    name: '$5,000,001 - $20,000,000',
  },
  {
    name: '$20,000,001 - $100,000,000',
  },
  {
    name: '100,000,000',
  },
];

export const INDUSTRY_SELECT_OPTIONS = [
  {
    name: 'Accounting and Finance',
  },
  {
    name: 'Applicant Tracking Software (ATS)',
  },
  {
    name: 'Artificial Intelligence',
  },
  {
    name: 'Back Office Management',
  },
  {
    name: 'Business Intelligence and Analytics',
  },
  {
    name: 'Business Process Management',
  },
  {
    name: 'CAD & PLM',
  },
  {
    name: 'Cloud Computing',
  },
  {
    name: 'Collaboration & Productivity',
  },
  {
    name: 'Communication Technology',
  },
  {
    name: 'Content Management',
  },
  {
    name: 'Customer Data Platform (CDP)',
  },
  {
    name: 'Customer Relationship Management (CRM)',
  },
  {
    name: 'Customer Support and Help Desk',
  },
  {
    name: 'Cybersecurity',
  },
  {
    name: 'Data Privacy',
  },
  {
    name: 'Document Management',
  },
  {
    name: 'E-Commerce',
  },
  {
    name: 'Employee Performance Management',
  },
  {
    name: 'Engineering and Development Tools',
  },
  {
    name: 'Enterprise Resource Planning',
  },
  {
    name: 'Expense Management',
  },
  {
    name: 'Field Service Management',
  },
  {
    name: 'FinTech',
  },
  {
    name: 'Governance, Risk & Compliance',
  },
  {
    name: 'Human Resource Management (HRM)',
  },
  {
    name: 'Identity and Access Management',
  },
  {
    name: 'IoT Management',
  },
  {
    name: 'IT Infrastructure',
  },
  {
    name: 'IT Management',
  },
  {
    name: 'Learning Management Software (LMS)',
  },
  {
    name: 'Legal Tech',
  },
  {
    name: 'MarTech',
  },
  {
    name: 'Procurement and Vendor Management',
  },
  {
    name: 'Sales Tools',
  },
  {
    name: 'Supply Chain & Logistics',
  },
];
