import React from 'react';
import { Typography, Rating } from '@mui/material';
import styled from 'styled-components';
import { screenMdMin } from '../../../shared/styles';
import ErrorMessage from '../../../shared/components/ErrorMessage';

type ReviewRatingProps = {
  value: string;
  handleChange?: any;
  label: string;
  name: string;
  errors?: any;
  isReadOnly?: boolean;
};

const Wrapper = styled.div<{ fullW?: boolean }>`
  flex-direction: column;
  width: 100%;
  @media ${screenMdMin} {
    ${({ fullW }) =>
      fullW &&
      `
    width: 22%
  `}
  }
`;

const StyledHeader = styled.strong`
  margin: var(--spacing-sm) 0;
`;

const StyledRating = styled(Rating)`
  margin: var(--spacing-sm) 0;
`;

const ReviewRating = (props: ReviewRatingProps) => {
  const { value, handleChange, label, name, errors, isReadOnly } = props;
  return (
    <Wrapper fullW={handleChange}>
      <Typography component="legend">
        <StyledHeader>{label}</StyledHeader>
      </Typography>
      <StyledRating
        onChange={handleChange}
        name={name}
        size="large"
        value={Number(value)}
        readOnly={isReadOnly}
      />
      {errors && !value && errors[name] && (
        <ErrorMessage key={`${name}-error`} error={errors[name]} />
      )}
    </Wrapper>
  );
};

export default ReviewRating;
