import styled from 'styled-components';
import { screenMdMin } from '../../../shared/styles';
import { BasicReviewType } from '../types/company.types';
import CompanyReview from './CompanyReview';

type CompanyProfileReviewProps = {
  review: BasicReviewType;
  onClick?: () => void;
  handleOpenReviewDialog: (reviewId?: number, isEdit?: boolean) => void;
};

const CardWrapper = styled.div`
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  margin-bottom: var(--spacing-md);
  width: 100%;
  padding: var(--spacing-md);
  :hover {
    top: calc(50% - 2px);
    border: 1px solid rgba(#f2f4f8, 0);
    box-shadow:
      0 15px 20px rgba(0, 0, 0, 0.05),
      0 0 5px rgba(0, 0, 0, 0.1);
  }
  @media ${screenMdMin} {
    align-items: center;
    flex-direction: column;
  }
`;

const CompanyProfileReview = (props: CompanyProfileReviewProps) => {
  const { review, onClick, handleOpenReviewDialog } = props;

  return (
    <CardWrapper onClick={onClick}>
      <CompanyReview
        review={review}
        handleOpenReviewDialog={handleOpenReviewDialog}
      />
    </CardWrapper>
  );
};

export default CompanyProfileReview;
