import * as React from 'react';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';
import CompanyProfileBanner from './CompanyProfileBanner';
import styled from 'styled-components';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Company } from '../../authentication/types/types.auth';
import CompanyProfileRatings from './CompanyProfileReviews';
import LoadingProgress from '../../../shared/components/LoadingProgress';
import CompanyProfileHeader from './CompanyProfileHeader';
import ReviewDialog from '../../review/components/ReviewDialog';
import CustomSnackbar, {
  SnackBarConfig,
} from '../../../shared/components/Snackbar';
import { BasicReviewType } from '../types/company.types';
import { UserContext } from '../../../shared/contexts/UserContext';

const Container = styled.div`
  max-width: 100%;
  padding: var(--spacing-md);
`;

const CompanyProfile = () => {
  const [currentCompany, setCurrentCompany] = useState<Company>();
  const [isViewMode, setIsViewMode] = useState<boolean | undefined>(false);
  const [reviewViewId, setReviewViewId] = useState<number | null>(null);
  const [companyReviews, setCompanyReviews] =
    useState<Array<BasicReviewType>>();
  const [snackbarConfig, setSnackbarConfig] = useState<SnackBarConfig>();
  const [openReviewDialog, setOpenReviewDialog] = useState(false);

  const { name, reviewId } = useParams();

  const { currentUser, reload, setReload } = useContext(UserContext);

  const reviewDialogChange = () => {
    if (!currentUser || !currentUser.id) {
      setSnackbarConfig({
        message: 'You must be logged in to use this feature.',
        open: true,
        type: 'error',
      });
      return;
    } else {
      setOpenReviewDialog(true);
    }
  };

  const handleSnackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const cancelRating = () => {
    setSnackbarConfig({
      type: 'error',
      message: 'An error has occurred, please try again later.',
      open: true,
    });
  };

  const ratingSuccess = () => {
    if (isViewMode) return;
    setSnackbarConfig({
      type: 'success',
      message: 'Review successfully submitted, thank you!',
      open: true,
    });
  };

  const getCompanyInfo = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/company/${name}`
      );
      setCurrentCompany(res.data);
    } catch (error) {
      setSnackbarConfig({
        open: true,
        type: 'error',
        message: 'Server error, please try again later',
      });
    }
  };

  const getCompanyReviews = async () => {
    if (!currentCompany?.id || !currentUser?.idToken) return;
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/reviews-companies/${currentCompany?.id}`,
        {
          headers: {
            Authorization: currentUser?.idToken,
          },
        }
      );
      setCompanyReviews(res.data);
    } catch (error) {
      setSnackbarConfig({
        open: true,
        type: 'error',
        message: 'Server error, please try again later',
      });
    }
  };

  const isCompanyFollowed =
    currentUser?.Companies?.map(
      (company: Company) => company.companyName
    ).includes(currentCompany?.companyName) || false;

  const followCompany = async () => {
    if (!currentUser) {
      setSnackbarConfig({
        message: 'You must be logged in to use this feature.',
        open: true,
        type: 'error',
      });
      return;
    }
    try {
      const updateCompanyInput = {
        CompanyId: currentCompany?.id,
        UserId: currentUser.id,
      };

      await axios.post(
        isCompanyFollowed
          ? `${process.env.REACT_APP_API_BASE_URL}/users/unfollow-company`
          : `${process.env.REACT_APP_API_BASE_URL}/users/follow-company`,
        updateCompanyInput,
        {
          headers: {
            Authorization: currentUser?.idToken,
          },
        }
      );
      setReload(reload + 1);
      setSnackbarConfig({
        message: `${currentCompany?.companyName} Succesfully ${
          isCompanyFollowed ? 'Unfollowed' : 'Followed'
        }.`,
        open: true,
        type: 'success',
      });
    } catch (error) {}
  };

  const handleOpenReviewDialog = (reviewId?: number, isViewMode?: boolean) => {
    setOpenReviewDialog(true);
    setIsViewMode(isViewMode);
    setReviewViewId(reviewId || null);
  };

  useEffect(() => {
    getCompanyInfo();
  }, [reload]);

  useEffect(() => {
    getCompanyReviews();
  }, [currentCompany, currentUser?.idToken, reload]);

  useEffect(() => {
    if (!reviewId) return;
    handleOpenReviewDialog(parseInt(reviewId), true);
  }, [reviewId]);

  if (!currentCompany) {
    return <LoadingProgress />;
  }

  return (
    <Container>
      <ReviewDialog
        open={openReviewDialog}
        setOpen={setOpenReviewDialog}
        companyName={currentCompany.companyName || ''}
        companyId={currentCompany.id || 0}
        cancelRating={cancelRating}
        ratingSuccess={ratingSuccess}
        isViewMode={isViewMode}
        reviewViewId={reviewViewId || 0}
        setReviewViewId={setReviewViewId}
        setIsViewMode={setIsViewMode}
        getAllReviews={getCompanyReviews}
      />
      <CompanyProfileHeader
        name={name || ''}
        openReviewDialog={reviewDialogChange}
        followCompany={followCompany}
        isCompanyFollowed={isCompanyFollowed}
      />
      <CompanyProfileBanner currentCompany={currentCompany} />
      <Grid container spacing={5} sx={{ mt: 1 }}>
        <CompanyProfileRatings
          handleOpenReviewDialog={handleOpenReviewDialog}
          title={name + ' ' + 'Ratings'}
          reviews={companyReviews || []}
        />
      </Grid>
      <CustomSnackbar config={snackbarConfig} setOpen={handleSnackBarClose} />
    </Container>
  );
};

export default CompanyProfile;
