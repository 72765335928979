import React from 'react';
import styled from 'styled-components';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ButtonWrapper, StyledPublishButton } from '../styles/review.styles';
import { REVIEWSTEPENUM } from '../types/review.types';
import { P } from '../../../shared/styles';

type ReviewFormButtonsProps = {
  handleContinueReview: any;
  onSubmit: (e: any) => void;
  submitButtonText: string;
  nextButtonText: string;
  reviewStep?: REVIEWSTEPENUM;
  disabled?: boolean;
  isViewMode?: boolean;
};

const ButtonTextWrapper = styled.div`
  flex-direction: column;
  max-width: 250px;
`;

const StyledButtonText = styled(P)`
  margin-top: var(--spacing-sm);
  margin-left: var(--spacing-xs);
  font-size: 14px;
`;

const ReviewFormButtons = (props: ReviewFormButtonsProps) => {
  const {
    onSubmit,
    handleContinueReview,
    nextButtonText,
    submitButtonText,
    disabled,
    reviewStep,
    isViewMode,
  } = props;
  return (
    <ButtonWrapper>
      {reviewStep === REVIEWSTEPENUM.BASIC_REVIEW && !isViewMode && (
        <StyledPublishButton
          variant="contained"
          type="submit"
          onClick={(e: any) => onSubmit(e)}
        >
          {submitButtonText}
        </StyledPublishButton>
      )}
      &nbsp; &nbsp;
      <ButtonTextWrapper>
        <StyledPublishButton
          disabled={disabled}
          variant="contained"
          type="submit"
          onClick={handleContinueReview}
          endIcon={<ArrowForwardIcon />}
        >
          {nextButtonText}
        </StyledPublishButton>

        {nextButtonText === 'Make it a Deep Dive' && !isViewMode && (
          <StyledButtonText>
            Deep dive reviews are the best way to empower other sales
            professionals by providing deeper intelligence into the buying
            process.
          </StyledButtonText>
        )}
      </ButtonTextWrapper>
    </ButtonWrapper>
  );
};

export default ReviewFormButtons;
