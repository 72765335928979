import { Autocomplete } from '@mui/material';
import styled from 'styled-components';

export const TableHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: var(--spacing-md) 0;
`;

export const StyledAutocomplete = styled(Autocomplete)`
  width: 30%;
`;
