import styled from 'styled-components';
import { useCallback, useContext, useEffect, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, IconButton } from '@mui/material';
import { UserPoolData } from '../../scenes/authentication/types/types.auth';
import SideDrawer from './SideDrawer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Breakpoints, screenLgMin } from '../styles';
import useWindowResize, {
  WindowSizeEnum,
  Dimensions,
} from '../hooks/useWindowResize';
import { preloginPaths } from '../constants';

type NavBarProps = {
  setIsNavOpen: (isOpen: boolean) => void;
  isNavOpen: boolean;
  transparent: boolean;
};

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
`;

const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-left: auto;
  gap: var(--spacing-xs);
  overflow: hidden;
  white-space: nowrap;
  flex-wrap: nowrap;
  @media ${screenLgMin} {
    margin-top: 0;
  }
`;

const StyledButton = styled(Button)`
  margin: 0 var(--spacing-xs);
  color: var(--color-white);
  border-radius: 30px;
  border: 1px solid var(--color-white);
  min-width: 150px;
  height: 40px;
  font-size: 12px;
  text-transform: capitalize;
  white-space: nowrap;
  @media ${screenLgMin} {
    font-size: 14px;
  }
`;

const Image = styled.img`
  width: 75px;
  height: auto;
  margin-left: 100px;
  @media ${screenLgMin} {
    width: 100px;
  }
`;

const NavigationContainer = styled.div`
  padding: var(--spacing-md);
`;

const TopNav = styled.div<{ transparent?: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-md) 0;
  background-color: ${({ transparent }) =>
    transparent ? 'rgba(0, 0, 0, 0)' : '#0f2867'};
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;
`;

const Navbar = (props: NavBarProps) => {
  const { setIsNavOpen, isNavOpen, transparent } = props;

  const { currentUser, setCurrentUser } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [screenSize, setScreenSize] = useState<WindowSizeEnum>(
    window.innerWidth > Breakpoints.md
      ? WindowSizeEnum.LARGE
      : WindowSizeEnum.SMALL
  );

  const [isTransparent, setIsTransparent] = useState(location.pathname === '/');

  useEffect(() => {
    setIsTransparent(location.pathname === '/');
  }, [location.pathname]);

  const setSize = useCallback((dimensions: Dimensions) => {
    if (dimensions.width > Breakpoints.md) {
      setScreenSize(WindowSizeEnum.LARGE);
    } else {
      setScreenSize(WindowSizeEnum.SMALL);
    }
  }, []);

  useWindowResize(setSize);

  const user =
    currentUser &&
    new CognitoUser({
      Username: currentUser?.email || '',
      Pool: new CognitoUserPool(UserPoolData),
    });

  const logOut = () => {
    user?.signOut();
    setCurrentUser(null);
    navigate('/');
    setIsNavOpen(false);
  };

  return (
    <>
      {currentUser && !preloginPaths.includes(location.pathname) && (
        <>
          <NavigationContainer role="app-nav">
            <IconButton
              onClick={() => setIsNavOpen(!isNavOpen)}
              sx={{ mr: 2, ...(isNavOpen && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
          </NavigationContainer>
          <SideDrawer
            isOpen={isNavOpen}
            setIsOpen={setIsNavOpen}
            logOut={logOut}
          />
        </>
      )}
      {preloginPaths.includes(location.pathname) && (
        <TopNav role="public-nav" transparent={isTransparent}>
          <Container>
            <StyledLink to="/">
              <Image src={require('../../assets/images/kandir_logo.png')} />
            </StyledLink>
            <ButtonWrapper>
              {screenSize === WindowSizeEnum.LARGE && (
                <>
                  <StyledButton onClick={() => navigate('/company-search')}>
                    Company Search
                  </StyledButton>
                  <StyledButton onClick={() => navigate('/pricing')}>
                    Pricing
                  </StyledButton>
                  <StyledButton onClick={() => navigate('/sign-up')}>
                    Sign Up
                  </StyledButton>
                </>
              )}
              <StyledButton onClick={() => navigate('/sign-in')}>
                Login
              </StyledButton>
            </ButtonWrapper>
          </Container>
        </TopNav>
      )}
    </>
  );
};

export default Navbar;
