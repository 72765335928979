import React from 'react';
import { FeatureItem, IconWrapper } from '../styles/PricingTable';

interface FeatureProps {
  children: React.ReactNode;
}

export const Feature: React.FC<FeatureProps> = ({ children }) => {
  return (
    <FeatureItem>
      <IconWrapper className="icon-check">&#10003;</IconWrapper>
      {children}
    </FeatureItem>
  );
};
