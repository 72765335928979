import React, { useContext, useEffect, useState } from 'react';
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import styled from 'styled-components';
import { UserContext } from '../contexts/UserContext';
import axios from 'axios';
import { SnackBarConfig } from './Snackbar';

type SideDrawerProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  logOut: () => void;
};

export const Main = MuiStyled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: '240px',
  }),
}));

const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  padding: var(--spacing-md);
  justify-content: space-between;
`;

const StyledImage = styled.img`
  height: 80px;
  cursor: pointer;
`;

const drawerWidth = 240;

const SideDrawer = (props: SideDrawerProps) => {
  const { isOpen, setIsOpen, logOut } = props;
  const [isAdmin, setIsAdmin] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState<SnackBarConfig>();
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handleSnackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const NAV_MAPPINGS = [
    {
      text: 'Company Search',
      icon: <SearchIcon />,
      onClick: () => navigate('/company-search'),
    },
    {
      text: 'Account Settings',
      icon: <SettingsIcon />,
      onClick: () => navigate('/account-settings'),
    },
    currentUser && isAdmin
      ? {
          text: 'Admin View',
          icon: <LeaderboardIcon />,
          onClick: () => navigate('/admin'),
        }
      : null,
    {
      text: currentUser ? 'Logout' : 'Login',
      icon: currentUser ? <LogoutIcon /> : <LoginIcon />,
      onClick: currentUser ? logOut : () => navigate('/sign-in'),
    },
  ];

  const checkIfUserIsAdmin = async () => {
    if (!currentUser?.idToken) return;
    const input = { email: currentUser?.email };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/change-user-group/check`,
        input,
        {
          headers: {
            Authorization: currentUser?.idToken,
          },
        }
      );
      setIsAdmin(res.data.isAdmin);
    } catch (error) {
      setSnackbarConfig({
        message: 'An error has occurred, please try again later',
        type: 'error',
        open: true,
      });
    }
  };

  useEffect(() => {
    if (isAdmin) return;
    checkIfUserIsAdmin();
  }, [currentUser?.idToken]);

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={isOpen}
      >
        <DrawerHeader>
          <IconButton onClick={() => navigate('/welcome')}>
            <StyledImage src={require('../../assets/images/kandir_logo.png')} />
          </IconButton>
          <IconButton onClick={() => setIsOpen(false)}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        {currentUser?.firstName && currentUser?.lastName && (
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/welcome')}>
              <ListItemText
                primary={
                  'Hi, ' + currentUser?.firstName + ' ' + currentUser?.lastName
                }
              />
            </ListItemButton>
          </ListItem>
        )}
        <Divider />
        <List>
          {NAV_MAPPINGS.map((navLink, index) =>
            navLink ? (
              <ListItem key={index} disablePadding>
                {!currentUser && navLink.text === 'Account Settings' ? (
                  <span></span>
                ) : (
                  <ListItemButton onClick={navLink.onClick}>
                    <ListItemIcon>{navLink.icon}</ListItemIcon>
                    <ListItemText primary={navLink.text} />
                  </ListItemButton>
                )}
              </ListItem>
            ) : null
          )}
        </List>
      </Drawer>
    </>
  );
};

export default SideDrawer;
