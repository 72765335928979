import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TablePagination } from '@mui/material';
import styled from 'styled-components';
import { HeadCell } from './TableHeader';
import { AdminActionsEnum } from '../../../scenes/admin/types/admin.types';
import { EnhancedTableHead } from '../../../scenes/welcome/components/ReviewTable';
import { SortArray } from '../../utils/Sorting';

type CustomTableProps<T extends Record<string, string | number | Date>> = {
  headCells: Array<HeadCell>;
  rows: Array<T>;
  tabChange?: string;
  inviteNewUser?: any;
  openCompanyApprovalModal?: (companyName: string, companyId: number) => void;
  openMigrateReviewModal?: any;
};

export const StyledActionButton = styled.button<{ typeVariant: string }>`
  background-color: ${(props) =>
    props.typeVariant === 'create'
      ? '#4CAF50'
      : props.typeVariant === 'delete'
        ? '#FF6347'
        : 'darkgray'};
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border-radius: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) =>
      props.typeVariant === 'create'
        ? '#4CAF50'
        : props.typeVariant === 'delete'
          ? '#FF6347'
          : 'darkgray'};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px
      ${(props) =>
        props.typeVariant === 'create'
          ? '#4CAF50'
          : props.typeVariant === 'delete'
            ? '#FF6347'
            : 'darkgray'};
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  padding: '24px',
  '&:nth-of-type(odd)': {
    backgroundColor: '#f5f5f5',
  },
}));

const CustomTablePagination = styled(TablePagination)`
  .MuiTablePagination-selectLabel {
    margin-top: var(--spacing-sm);
  }
  .MuiTablePagination-displayedRows {
    margin-top: var(--spacing-sm);
  }
`;

const CustomTable = <T extends Record<string, string | number | Date | any>>(
  props: CustomTableProps<T>
) => {
  const {
    headCells,
    rows,
    tabChange,
    inviteNewUser,
    openCompanyApprovalModal,
    openMigrateReviewModal,
  } = props;
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<boolean>(false);
  const [orderBy, setOrderBy] = useState('');
  const [sortedRows, setSortedRows] = useState<any>(rows);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = () => {
    setOrder(!order);
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const resolveAdminActions = (
    actions: any,
    email: string,
    name?: string,
    companyName?: string,
    companyId?: number
  ) => {
    return actions.map((action: any) => {
      switch (action.action) {
        case AdminActionsEnum.SEND_INVITE:
          return (
            <StyledActionButton
              key={action.action}
              typeVariant={action.typeVariant}
              onClick={() => {
                inviteNewUser && inviteNewUser(email, name);
              }}
            >
              Send Invite
            </StyledActionButton>
          );
        case AdminActionsEnum.DELETE_WAITLIST_USER:
          return (
            <StyledActionButton
              typeVariant={action.typeVariant}
              key={action.action}
              disabled={true}
            >
              Delete
            </StyledActionButton>
          );
        case AdminActionsEnum.APPROVE_COMPANY:
          return (
            <StyledActionButton
              typeVariant={action.typeVariant}
              key={action.action}
              onClick={() => {
                openCompanyApprovalModal &&
                  openCompanyApprovalModal(companyName || '', companyId || 0);
              }}
            >
              Approve Company
            </StyledActionButton>
          );
        case AdminActionsEnum.MIGRATE_COMPANY_REVIEWS:
          return (
            <StyledActionButton
              typeVariant={action.typeVariant}
              key={action.action}
              onClick={() => {
                openMigrateReviewModal &&
                  openMigrateReviewModal(companyName || '', companyId || 0);
              }}
            >
              Migrate Reviews
            </StyledActionButton>
          );
        case AdminActionsEnum.PROMOTE_TO_ADMIN:
          return (
            <StyledActionButton
              typeVariant={action.typeVariant}
              key={action.action}
            >
              Promote to Admin
            </StyledActionButton>
          );

        default:
          return null;
      }
    });
  };

  useEffect(() => {
    setSortedRows(rows);
  }, [rows]);

  useEffect(() => {
    const newReviewOrder = SortArray(rows, orderBy, order);
    setSortedRows(newReviewOrder);
  }, [order, orderBy, tabChange]);

  if (!rows.length) {
    return <span>No records yet exist.</span>;
  }

  return (
    <Paper>
      <TableContainer>
        <Table
          sx={{ minWidth: 750, padding: '24px' }}
          aria-labelledby="tableTitle"
        >
          <EnhancedTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            setOrder={handleRequestSort}
          />
          <TableBody sx={{ padding: '24px' }}>
            {sortedRows
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any, index: number) => {
                return (
                  <StyledTableRow key={index}>
                    {Object.keys(row).map((property, propertyIndex) => (
                      <TableCell key={propertyIndex} align="left">
                        {property === 'actions' ? (
                          //@ts-ignore
                          resolveAdminActions(
                            row.actions,
                            row.email,
                            row.firstName,
                            row.companyName,
                            row.id
                          )
                        ) : (
                          <>{row[property]}</>
                        )}
                      </TableCell>
                    ))}
                  </StyledTableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomTablePagination
        rowsPerPageOptions={[5, 10, 25]}
        //@ts-ignore
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default CustomTable;
