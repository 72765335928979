import React from 'react';
import Dialog from '@mui/material/Dialog';
import styled from 'styled-components';
import { Button, Typography } from '@mui/material';

const StyledForm = styled.form`
  max-width: 85%;
  margin: 0 auto;
  padding: var(--spacing-md);
`;

const StyledText = styled(Typography)`
  margin: var(--spacing-sm) 0;
`;

const Title = styled.h2`
  font-size: 24px;
  text-decoration: bold;
  margin: auto;
  margin-top: var(--spacing-sm);
`;

const StyledSubmitButton = styled(Button)`
  && {
    width: 80px;
    margin: var(--spacing-sm) auto;
    margin-left: var(--spacing-sm);
  }
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
  }
`;

interface PricingQuoteModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (email: string, slug?: string) => void;
  onEmailChange: (email: string) => void;
  email: string;
  slug?: string;
}

export const PricingQuoteModal: React.FC<PricingQuoteModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  onEmailChange,
  email,
  slug,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onEmailChange(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(email, slug);
  };

  return (
    <StyledDialog open={isOpen} onClose={onClose}>
      <StyledForm onSubmit={handleSubmit}>
        <Title>Enter your email address</Title>
        <StyledText>
          We'll be in touch to discuss your Kandir plan options
        </StyledText>
        <input
          type="email"
          name="email"
          value={email}
          onChange={handleChange}
          required
        />
        <StyledSubmitButton type="submit" variant="contained" color="primary">
          Submit
        </StyledSubmitButton>
      </StyledForm>
    </StyledDialog>
  );
};
