import { PricingCardData } from '../types';

export const pricingCards: PricingCardData[] = [
  {
    title: 'Individual',
    tagline: 'Industry-leading intelligence',
    features: [
      'Access to Basic reviews for all companies',
      'Access to 25 Deep Dive reviews per term',
      'Access to enhanced company data',
    ],
    buttonText: '$977 per year',
    slug: 'individual',
  },
  {
    title: 'Team',
    tagline: 'Comprehensive access for sales organizations',
    features: [
      'Everything in Individual',
      'Access to Unlimited Deep Dive reviews',
      'One custom pipeline review target',
      '"Ask the Seller" access',
    ],
    buttonText: 'Request a Quote',
    slug: 'team',
  },
];
