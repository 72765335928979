import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import PersonIcon from '@mui/icons-material/Person';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { P } from '../../../shared/styles';

export enum USERTYPENUM {
  USER = 'User',
  COMPANY = 'Company',
}

type UserTypeSelectProps = {
  userType: USERTYPENUM | null;
  onChange: (
    event: React.MouseEvent<HTMLElement>,
    newUserType: USERTYPENUM | null
  ) => void;
};

const Wrapper = styled.div`
  padding-top: var(--spacing-md);
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const UserTypeSelect = (props: UserTypeSelectProps) => {
  const { userType, onChange } = props;
  return (
    <Wrapper>
      <ToggleButtonGroup
        value={userType}
        exclusive
        onChange={onChange}
        aria-label="text alignment"
      >
        <ToggleButton value={USERTYPENUM.USER}>
          <PersonIcon />
          <P>Individual Sign Up</P>
        </ToggleButton>
        <ToggleButton value={USERTYPENUM.COMPANY}>
          <ApartmentIcon />
          <P>Company Sign Up</P>
        </ToggleButton>
      </ToggleButtonGroup>
    </Wrapper>
  );
};

export default UserTypeSelect;
