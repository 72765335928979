import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { H5 } from '../../../shared/styles';
import ReviewQuestion, {
  QuestionWrapper,
  StyledQuestionText,
} from './ReviewQuestion';
import {
  RATING_MAPPINGS,
  BasicReviewType,
} from '../../company-search/types/company.types';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  BASIC_REVIEW_MAPPINGS,
  RATING_FORM_FIELDS,
  RATING_FORM_FIELDS_TYPE,
  REVIEWSTEPENUM,
} from '../types/review.types';
import ReviewRating from './ReviewRating';
import { Textarea } from '../../../shared/components/TextArea';
import { UserContext } from '../../../shared/contexts/UserContext';
import axios from 'axios';
import _ from 'underscore';
import {
  StyledText,
  StyledSelectWrapper,
  Container,
  StyledTextField,
} from '../styles/review.styles';
import ReviewFormButtons from './ReviewFormButtons';
import LoadingProgress from '../../../shared/components/LoadingProgress';
import { useExtendState } from '../../../shared/utils/ExtendState';
import ErrorMessage from '../../../shared/components/ErrorMessage';

type BasicReviewTypeProps = {
  successDeal: (isViewMode?: boolean) => void;
  companyId: number;
  companyName: string;
  cancelRating: () => void;
  setReload: (reload: number) => void;
  reload: number;
  setReviewStep: (reviewStep: REVIEWSTEPENUM) => void;
  setCurrentReviewId: (reviewId: number) => void;
  currentReviewId: number | null;
  currentReview: BasicReviewType;
  setRfp: (rfp: boolean) => void;
  rfp: boolean;
  isViewMode: boolean;
  isAddCompany?: boolean;
};

const StyledHeader = styled(H5)`
  margin: auto;
  text-align: center;
  margin-bottom: var(--spacing-lg);
`;

const BasicReview = (props: BasicReviewTypeProps) => {
  const {
    successDeal,
    companyId,
    companyName,
    cancelRating,
    setReviewStep,
    setCurrentReviewId,
    currentReviewId,
    currentReview,
    setRfp,
    setReload,
    reload,
    isViewMode,
    isAddCompany,
  } = props;

  const [formFields, extendFormFields, setFormFields] =
    useExtendState<BasicReviewType>(currentReview ?? ({} as BasicReviewType));

  const ratingValues = {
    transparencyRating:
      currentReview?.Ratings?.find((rating) => rating.type === 'transparency')
        ?.rating || '',
    clarityRating:
      currentReview?.Ratings?.find((rating) => rating.type === 'clarity')
        ?.rating || '',
    responsivenessRating:
      currentReview?.Ratings?.find((rating) => rating.type === 'responsiveness')
        ?.rating || '',
    understandingRating:
      currentReview?.Ratings?.find((rating) => rating.type === 'understanding')
        ?.rating || '',
  };

  const [ratingFormFields, setRatingFormFields] =
    useState<RATING_FORM_FIELDS_TYPE>(ratingValues ?? RATING_FORM_FIELDS);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [newCompany, setNewCompany] = useState({
    companyName: '',
    stockTicker: '',
    isApproved: false,
  });
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handleRatingChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setRatingFormFields({ ...ratingFormFields, [name]: value });
  };

  let buttonText;
  switch (true) {
    case currentReviewId !== null:
      buttonText = 'Purchase Details';
      break;
    case isViewMode && currentReview.reviewDepth === 'BASIC':
      buttonText = 'Done';
      break;
    case isViewMode:
      buttonText = 'View Purchase Details';
      break;
    default:
      buttonText = 'Make it a Deep Dive';
      break;
  }

  const validate = () => {
    const requiredFields = [
      'dealArea',
      'dealType',
      'dealSize',
      'dealLength',
      'dealClose',
      'commentBuyerBetter',
      'commentBuyerWell',
      'commentWinLose',
      'commentAdvice',
    ];

    const ratingFields = [
      { name: 'transparencyRating', type: 'transparency' },
      { name: 'clarityRating', type: 'clarity' },
      { name: 'understandingRating', type: 'understanding' },
      { name: 'responsivenessRating', type: 'responsiveness' },
    ];

    const errors: any = {};

    requiredFields.forEach((field) => {
      //@ts-ignore
      if (!formFields[field]) {
        errors[field] = 'This field is required';
      }
    });

    ratingFields.forEach(({ name, type }) => {
      if (
        //@ts-ignore
        !ratingFormFields?.[name] &&
        !currentReview.Ratings?.find((rating) => rating.type === type)?.rating
      ) {
        errors[name] = 'This field is required';
      }
    });

    setErrors(errors);
    return !_.isEmpty(errors);
  };

  const submitBasicReviewType = async (
    e: React.FormEvent<HTMLFormElement>,
    continueReview?: boolean
  ) => {
    e.preventDefault();

    const hasErrors = validate();

    if (hasErrors) return;

    setLoading(true);

    let createdCompany;

    if (isAddCompany) {
      const createdCompanyResponse = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/company`,
        newCompany,
        {
          headers: {
            Authorization: currentUser?.idToken,
          },
        }
      );
      createdCompany = createdCompanyResponse.data;
    }

    const reviewInput = {
      ...formFields,
      reviewType: 'OWNED',
      reviewDepth: currentReview.reviewDepth || 'BASIC',
      UserId: currentUser?.id,
      CompanyId: createdCompany?.id || companyId,
      companyName: createdCompany?.companyName || companyName,
      key: REVIEWSTEPENUM.BASIC_REVIEW,
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/review`,
        reviewInput,
        {
          headers: {
            Authorization: currentUser?.idToken,
          },
        }
      );
      const ratingsInput = {
        companyId: createdCompany?.id || companyId,
        reviewId: res.data,
        ratings: {
          transparency: ratingFormFields.transparencyRating,
          clarity: ratingFormFields.clarityRating,
          responsiveness: ratingFormFields.responsivenessRating,
          understanding: ratingFormFields.understandingRating,
        },
      };
      if (currentReview && !isViewMode) {
        await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/reviews/ratings`,
          ratingsInput,
          {
            headers: {
              Authorization: currentUser?.idToken,
            },
          }
        );
      }

      if (continueReview) {
        if (currentReview?.reviewDepth === 'BASIC' && isViewMode) {
          navigate(`/company-search/${companyName}`, { replace: true });
          successDeal(true);
          return;
        }
        setReviewStep(REVIEWSTEPENUM.PURCHASE_DETAILS);
        setCurrentReviewId(res.data);
        setLoading(false);
        return;
      }
    } catch (error) {
      cancelRating();
    }
    setLoading(false);
    successDeal();
    setReload(reload + 1);
  };

  useEffect(() => {
    setLoading(true);
    setFormFields(currentReview ?? ({} as BasicReviewType));
    setLoading(false);
  }, [currentReview]);

  useEffect(() => {
    if (formFields.dealRfp === 'Yes') {
      setRfp(true);
    } else {
      setRfp(false);
    }
  }, [formFields.dealRfp]);

  if (loading) {
    return <LoadingProgress />;
  }

  return (
    <Container>
      <form>
        {!isViewMode && (
          <StyledHeader>
            Thank you for sharing with Kandir! Let's start by collecting the
            basic review information.
          </StyledHeader>
        )}

        {isAddCompany && (
          <>
            {' '}
            <QuestionWrapper>
              <StyledQuestionText>
                What is the name of the the company you are reviewing?
              </StyledQuestionText>
              <div style={{ width: '80%' }}>
                <StyledTextField
                  onChange={(e) =>
                    setNewCompany({
                      ...newCompany,
                      companyName: e.target.value,
                    })
                  }
                  size="small"
                  required
                />
                {errors.economicBuyerName && (
                  <ErrorMessage error={errors.economicBuyerName} />
                )}
              </div>
            </QuestionWrapper>
            <QuestionWrapper>
              <StyledQuestionText>
                What is the NASDAQ Stock Ticker of the the company you are
                reviewing?
              </StyledQuestionText>
              <div style={{ width: '80%' }}>
                <StyledTextField
                  required
                  onChange={(e) =>
                    setNewCompany({
                      ...newCompany,
                      stockTicker: e.target.value,
                    })
                  }
                  size="small"
                />
                {errors.economicBuyerName && (
                  <ErrorMessage error={errors.economicBuyerName} />
                )}
              </div>
            </QuestionWrapper>
          </>
        )}

        {BASIC_REVIEW_MAPPINGS.map((reviewQuestion) => (
          <ReviewQuestion
            errors={errors}
            isViewMode={isViewMode}
            key={reviewQuestion.name}
            question={reviewQuestion.question}
            isNarrow={reviewQuestion.options.length > 8}
            options={reviewQuestion.options}
            name={reviewQuestion.name}
            formFields={formFields}
            handleFormFieldChange={(e) =>
              extendFormFields({ [e.target.name]: e.target.value })
            }
          />
        ))}
        <Grid container>
          <Grid item xs={12} sm={6}>
            <StyledText>What did the buyer do well?</StyledText>
            <Textarea
              style={{ resize: 'none' }}
              name="commentBuyerWell"
              value={formFields.commentBuyerWell}
              onChange={(e) =>
                extendFormFields({ [e.target.name]: e.target.value })
              }
              required
              readOnly={isViewMode}
              minRows={4}
            />
            {errors.commentBuyerWell && (
              <ErrorMessage error={errors.commentBuyerWell} />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledText spaceleft>
              What could the buyer have done better?
            </StyledText>
            <Textarea
              style={{ resize: 'none' }}
              name="commentBuyerBetter"
              value={formFields.commentBuyerBetter}
              onChange={(e) =>
                extendFormFields({ [e.target.name]: e.target.value })
              }
              minRows={4}
              required
              readOnly={isViewMode}
              spaceleft
            />
            <div style={{ marginLeft: 'var(--spacing-md)' }}>
              {errors.commentBuyerBetter && (
                <ErrorMessage error={errors.commentBuyerBetter} />
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledText>Why did you win/lose the deal?</StyledText>
            <Textarea
              style={{ resize: 'none' }}
              onChange={(e) =>
                extendFormFields({ [e.target.name]: e.target.value })
              }
              name="commentWinLose"
              value={formFields.commentWinLose}
              minRows={4}
              required
              readOnly={isViewMode}
            />
            {errors.commentWinLose && (
              <ErrorMessage error={errors.commentWinLose} />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledText spaceleft>
              What advice do you have for the next seller?
            </StyledText>
            <Textarea
              style={{ resize: 'none' }}
              onChange={(e) =>
                extendFormFields({ [e.target.name]: e.target.value })
              }
              spaceleft
              value={formFields.commentAdvice}
              name="commentAdvice"
              minRows={4}
              required
              readOnly={isViewMode}
            />
            <div style={{ marginLeft: 'var(--spacing-md)' }}>
              {errors.commentAdvice && (
                <ErrorMessage error={errors.commentAdvice} />
              )}
            </div>
          </Grid>
          <StyledText>
            On a scale of 1 - 5, how would you rate the companies:
          </StyledText>
          <StyledSelectWrapper>
            {RATING_MAPPINGS.map((rating) => {
              return (
                <ReviewRating
                  key={rating.name}
                  handleChange={handleRatingChange}
                  errors={errors}
                  isReadOnly={isViewMode}
                  value={
                    //@ts-ignore
                    ratingFormFields[rating.name] ||
                    currentReview?.Ratings?.find(
                      (responseRating) =>
                        responseRating?.type === rating?.responseName
                    )?.rating
                  }
                  label={rating.label}
                  name={rating.name}
                />
              );
            })}
          </StyledSelectWrapper>
        </Grid>
        <ReviewFormButtons
          reviewStep={REVIEWSTEPENUM.BASIC_REVIEW}
          handleContinueReview={(e: React.FormEvent<HTMLFormElement>) => {
            submitBasicReviewType(e, true);
          }}
          submitButtonText="Publish Basic Review"
          nextButtonText={buttonText}
          onSubmit={submitBasicReviewType}
          isViewMode={isViewMode}
        />
      </form>
    </Container>
  );
};

export default BasicReview;
